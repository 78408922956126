import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2'

const config_input_show_ini = () => {
    return {
        type: types.CONFIG_INPUT_SHOW_INI
    };
};

const config_input_show_success = (options) => {
    return {
        type: types.CONFIG_INPUT_SHOW_SUCCESS,
        options
    }
}


const config_input_show_loading = (loading) => {
    return {
        type: types.CONFIG_INPUT_SHOW_LOADING,
        loading
    }
}

const addConfigInputInsuvey = (objUpdate, updateEnd) => (dispatch) => {
    dispatch(config_input_show_loading(true))
    axiosWrapper.axiosWithHeaders("post", api.createConfigInputInSurvey, objUpdate)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Thêm mới',
                        text: 'Thêm mới cấu hình hiển thị dữ liệu thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Thêm mới',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Thêm mới',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Thêm mới',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(config_input_show_loading(false));
        })
}
const updateConfigInputInsuvey = (objUpdate, updateEnd) => (dispatch) => {
    dispatch(config_input_show_loading(true))
    axiosWrapper.axiosWithHeaders("post", api.updateConfigInputInSurvey, objUpdate)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Cập nhật',
                        text: 'Cập nhật cấu hình hiển thị dữ liệu thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Cập nhật',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Cập nhật',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Cập nhật',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(config_input_show_loading(false));
        })
}

const delConfigInputInsuvey = (objUpdate, updateEnd) => (dispatch) => {
    dispatch(config_input_show_loading(true))
    axiosWrapper.axiosWithHeaders("post", api.deleteConfigInputInSurvey, objUpdate)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Xóa',
                        text: 'Xóa cấu hình hiển thị dữ liệu thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Xóa',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Xóa',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Xóa',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(config_input_show_loading(false));
        })
}

const getViewConfigInSurvey = (surveyId, projectId) => {
    return axiosWrapper.axiosWithHeaders("post", api.configViewInSurvey, { projectId, surveyId })
}

export {
    getViewConfigInSurvey,
    addConfigInputInsuvey,
    updateConfigInputInsuvey,
    delConfigInputInsuvey
}
