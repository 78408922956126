import * as types from '@/redux/contains/types';
import * as enums from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2'

const template_ini = () => {
    return {
        type: types.TEMPLATE_INI
    };
};

const template_success = (detail) => {
    return {
        type: types.TEMPLATE_SUCCESS,
        detail
    };
};



const template_loading = (loading) => {
    return {
        type: types.TEMPLATE_LOADING,
        loading
    }
}


const templateUserInCompany = (updateEnd) => (dispatch) => {
    dispatch(template_loading(true));
    axiosWrapper.axiosWithHeaders("get", api.templateImportUserInCompany)
        .then(response => {
            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === enums.STATUS_RESPONSE_OK) {

                    var element = document.createElement("a");
                    element.href = data.result;
                    element.setAttribute("download", "Template");
                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);

                    Swal.fire({
                        title: 'Tải template',
                        text: 'Tải template thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })

                } else {
                    Swal.fire({
                        title: 'Tải template',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Tải template',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Tải template',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(template_loading(false));
        })
}

const templateStoreUser = (obj, updateEnd) => (dispatch) => {
    dispatch(template_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.templateDownloadImportStoreUser, obj)
        .then(response => {

            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === enums.STATUS_RESPONSE_OK) {
                    var element = document.createElement("a");
                    element.href = data.result;
                    element.setAttribute("download", "Template");
                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);

                    Swal.fire({
                        title: 'Tải template',
                        text: 'Tải template thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })

                } else {
                    Swal.fire({
                        title: 'Tải template',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Tải template',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Tải template',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(template_loading(false));
        })
}

const templateStore = (obj, updateEnd) => {
    axiosWrapper.axiosWithHeaders("post", api.templateDownloadImportStore, obj)
        .then(response => {
            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data: {
                        status,
                        message,
                        result
                    }
                } = response;
                if (status === enums.STATUS_RESPONSE_OK) {
                    var element = document.createElement("a");
                    element.href = result;
                    element.setAttribute("download", "Template");
                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);

                    Swal.fire({
                        title: 'Tải template',
                        text: 'Tải template thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                } else {
                    console.log(`Error template store 213: ${message}`);
                }
            }
        })
        .catch((err) => {
            console.log(`Error template store 217: ${err.toString()}`);
        })
        .finally(() => {
            updateEnd();
        })
}

const templateDelStore = (obj, updateEnd) => {
    axiosWrapper.axiosWithHeaders("post", api.templateDownloadImportDelStore, obj)
        .then(response => {
            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data: {
                        status,
                        message,
                        result
                    }
                } = response;
                if (status === enums.STATUS_RESPONSE_OK) {

                    var element = document.createElement("a");
                    element.href = result;
                    element.setAttribute("download", "Template");
                    element.style.display = 'none';
                    document.body.appendChild(element);

                    element.click();

                    document.body.removeChild(element);

                    Swal.fire({
                        title: 'Tải template',
                        text: 'Tải template thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                } else {
                    Swal.fire('Lỗi',`Error template del store 260: ${message}`, 'error');
                }
            }
        })
        .catch((err) => {
            console.log(`Error template del store 265: ${err.toString()}`);
        })
        .finally(() => {
            updateEnd();
        })
}

const importStore = (obj, updateEnd, typeId) => (dispatch) => {
    let urlApi = "";
    switch (typeId) {
        case 1:
            urlApi = api.Import_Store
            break;
        case 2:
            urlApi = api.Import_Update_Store
            break;
        case 3:
            urlApi = api.Import_Delete_Store
            break;
        default:
            break;
    }
    dispatch(template_loading(true));
    axiosWrapper.axiosWithHeadersFormData("post", urlApi, obj)
        .then(response => {
            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data: {
                        status,
                        message,
                    }
                } = response;
                if (status === enums.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Import',
                        text: 'Đã ghi nhận import. Kết quả sẽ gửi về trong mail .!!',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire("Import", `Error import store 311: ${message}`, "error");
                }
            } 
        })
        .catch((err) => {
            console.log(`Error import store 316: ${err.toString()}`);
        })
        .finally(() => {
            dispatch(template_loading(false));
        });
}

const importStoreUser = (obj, updateEnd) => (dispatch) => {
    dispatch(template_loading(true))
    axiosWrapper.axiosWithHeadersFormData("post", api.Import_Store_User, obj)
        .then(response => {
            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data: {
                        status,
                        message,
                    }
                } = response;
                if (status === enums.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Import',
                        text: 'Đã ghi nhận import. Kết quả sẽ gửi về trong mail .!!',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })  
                    updateEnd();
                } else {
                    // console.log(`Error import store user 345: ${message}`);
                    Swal.fire("Import", `Error import store user 345: ${message}`, "error");
                }
            }
        })
        .catch((err) => {
            console.log(`Error import store user 350: ${err.toString()}`);
        })
        .finally(() => {
            dispatch(template_loading(false))
        })
}

const importUserInCompany = (obj, updateEnd) => (dispatch) => {
    dispatch(template_loading(true));
    axiosWrapper.axiosWithHeadersFormData("post", api.Import_User_Company, obj)
        .then(response => {
            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === enums.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Import',
                        text: 'Đã ghi nhận import. Kết quả sẽ gửi về trong mail .!!',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                } else {
                    Swal.fire({
                        title: 'Import',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Import',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Import',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(template_loading(false));
        })
}

const importUserInProject = (obj, updateEnd) => (dispatch) => {
    dispatch(template_loading(true));
    axiosWrapper.axiosWithHeadersFormData("post", api.Import_User_Project, obj)
        .then(response => {
            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === enums.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Import',
                        text: 'Đã ghi nhận import. Kết quả sẽ gửi về trong mail .!!',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                } else {
                    Swal.fire({
                        title: 'Import',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Import',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Import',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(template_loading(false));
        })
}

const downloadStore = (obj, updateEnd) => (dispatch) => {
    dispatch(template_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.Download_Store_List, obj)
        .then(response => {
            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === enums.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Tải danh sách',
                        text: 'Hệ thông sẽ gửi danh sách địa điểm vào mail của bạn  .!!',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Tải danh sách',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Tải danh sách',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Tải danh sách',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(template_loading(false));
        })
}


export {
    templateUserInCompany,
    templateStoreUser,
    templateStore,
    templateDelStore,
    importStore,
    importStoreUser,
    importUserInCompany,
    importUserInProject,
    downloadStore
}