import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2';

const input_type_ini = () => {
    return {
        type: types.INPUT_TYPE_INI
    };
};

const input_type_success = (options) => {
    return {
        type: types.INPUT_TYPE_SUCCESS,
        options
    }
}

const input_type_loading = (loading) => {
    return {
        type: types.INPUT_TYPE_LOADING,
        loading
    }
}

const getListInputType = () => {
    // dispatch(input_type_loading(true));
    return axiosWrapper.axiosWithHeaders("get", api.listInputTypeDropdown, null)
        // .then(response => {
        //     if (response.status === status.STATUS_RESPONSE_OK && response.data) {
        //         const {
        //             data
        //         } = response;
        //         if (data.status === status.STATUS_RESPONSE_OK) {
        //             dispatch(input_type_success(data.result));
        //         } else {
        //             Swal.fire({
        //                 title: 'Loại dữ liệu',
        //                 text: data.message,
        //                 imageUrl: '/img/octopus/success.svg',
        //                 imageWidth: 109,
        //                 imageHeight: 98,
        //                 imageAlt: 'Success',
        //                 showConfirmButton: false,
        //                 timer: 3000,
        //                 showCloseButton: true
        //             })
        //         }
        //     } else {
        //         Swal.fire({
        //             title: 'Loại dữ liệu',
        //             text: response.statusText,
        //             imageUrl: '/img/octopus/success.svg',
        //             imageWidth: 109,
        //             imageHeight: 98,
        //             imageAlt: 'Success',
        //             showConfirmButton: false,
        //             timer: 3000,
        //             showCloseButton: true
        //         })
        //     }
        // })
        // .catch((err) => {
        //     Swal.fire({
        //         title: 'Loại dữ liệu',
        //         text: err.toString(),
        //         imageUrl: '/img/octopus/success.svg',
        //         imageWidth: 109,
        //         imageHeight: 98,
        //         imageAlt: 'Success',
        //         showConfirmButton: false,
        //         timer: 3000,
        //         showCloseButton: true
        //     })
        // })
        // .finally(() => {
        //     dispatch(input_type_loading(false));
        // })
}

export {
    getListInputType,
}
