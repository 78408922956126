import { SERVICE_URI } from '@/config/index';
/*khai báo đường dẫn api*/
/*login*/
export const loginApi = `${SERVICE_URI}/api/login`;
export const loginApiOtp = `${SERVICE_URI}/api/login-otp`;
export const registerApiOtp = `${SERVICE_URI}/api/register-otp`;
export const checkUserExists = `${SERVICE_URI}/api/user-exists`;
export const sendOtp = `${SERVICE_URI}/api/otp`;
export const REFRESHTOKEN = `${SERVICE_URI}/api/refresh-token`;
export const updateProfile = `${SERVICE_URI}/api/user/updateprofile`;
export const updateVerified = `${SERVICE_URI}/api/user/authuser`;
/*upload*/
export const uploadApi = `${SERVICE_URI}/api/file`;

/*encrypt*/
export const encryptApi = `${SERVICE_URI}/api/test`;

/*company*/
export const listCompany = `${SERVICE_URI}/api/company/listcompany`;
export const createCompany = `${SERVICE_URI}/api/company/createcompany`;
export const updateCompany = `${SERVICE_URI}/api/company/updatecompany`;
export const detailCompany = `${SERVICE_URI}/api/company/detail`;
export const deleteCompany = `${SERVICE_URI}/api/company/delcompany`;
export const overviewCompany = `${SERVICE_URI}/api/company/overview`;

/*project*/
export const listProject = `${SERVICE_URI}/api/project/list`;
export const createProject = `${SERVICE_URI}/api/project/create`;
export const updateProject = `${SERVICE_URI}/api/project/update`;
export const detailProject = `${SERVICE_URI}/api/project/detail`;
export const overviewProject = `${SERVICE_URI}/api/project/overview`;
export const deleteProject = `${SERVICE_URI}/api/project/del`;


/*role-company*/
export const listRoleCompany = `${SERVICE_URI}/api/rolecompany/list`;
export const createRoleCompany = `${SERVICE_URI}/api/rolecompany/create`;
export const updateRoleCompany = `${SERVICE_URI}/api/rolecompany/update`;
export const detailRoleCompany = `${SERVICE_URI}/api/rolecompany/detail`;
export const deleteRoleCompany = `${SERVICE_URI}/api/rolecompany/del`;

/*user-role-company*/
export const listUserRoleCompany = `${SERVICE_URI}/api/userrolecompany/list`;
export const createUserRoleCompany = `${SERVICE_URI}/api/userrolecompany/create`;
export const updateUserRoleCompany = `${SERVICE_URI}/api/userrolecompany/update`;
export const detailUserRoleCompany = `${SERVICE_URI}/api/userrolecompany/detail`;
export const deleteUserRoleCompany = `${SERVICE_URI}/api/userrolecompany/del`;

/*project*/
/*role*/
export const listRoleProject = `${SERVICE_URI}/api/roleproject/list`;
export const createRoleProject = `${SERVICE_URI}/api/roleproject/create`;
export const updateRoleProject = `${SERVICE_URI}/api/roleproject/update`;
export const detailRoleProject = `${SERVICE_URI}/api/roleproject/detail`;
export const deleteRoleProject = `${SERVICE_URI}/api/roleproject/del`;

/*user-role*/
export const listUserRoleProject = `${SERVICE_URI}/api/userroleproject/list`;
export const createUserRoleProject = `${SERVICE_URI}/api/userroleproject/create`;
export const createUserRoleProjectByPhone = `${SERVICE_URI}/api/userroleproject/createbyphone`;
export const updateUserRoleProject = `${SERVICE_URI}/api/userroleproject/update`;
export const detailUserRoleProject = `${SERVICE_URI}/api/userroleproject/detail`;
export const deleteUserRoleProject = `${SERVICE_URI}/api/userroleproject/del`;

/*survey*/
export const listSurvey = `${SERVICE_URI}/api/survey/list`;
export const createSurvey = `${SERVICE_URI}/api/survey/create`;
export const updateSurvey = `${SERVICE_URI}/api/survey/update`;
export const detailSurvey = `${SERVICE_URI}/api/survey/detail`;
export const deleteSurvey = `${SERVICE_URI}/api/survey/del`;
export const listSurveyByProject = `${SERVICE_URI}/api/survey/listinproject`;
export const moveDataGroup = `${SERVICE_URI}/api/surveydata/movedatagroup`;



/*survey-data*/
export const listSurveyData = `${SERVICE_URI}/api/surveydata/list`;
export const detailSurveyData = `${SERVICE_URI}/api/surveydata/detail`;
export const detailSurveyDatav2 = `${SERVICE_URI}/api/surveydata/detailstore`;
export const detailSurveyInDate = `${SERVICE_URI}/api/surveydata/detailindate`;
export const approveUpdateSurvey = `${SERVICE_URI}/api/surveydata/updatedata`;
export const deleteSurveyData = `${SERVICE_URI}/api/surveydata/del`;

/*store*/
export const listStore = `${SERVICE_URI}/api/store/list`;
export const listColumnStore = `${SERVICE_URI}/api/store/listcolumn`;
export const createStore = `${SERVICE_URI}/api/store/create`;
export const updateStore = `${SERVICE_URI}/api/store/update`;
export const detailStore = `${SERVICE_URI}/api/store/detail`;
export const deleteStore = `${SERVICE_URI}/api/store/del`;
export const checkStoreCode = `${SERVICE_URI}/api/store/checkcode`;
export const deleteListUserStore = `${SERVICE_URI}/api/storeuser/delbystoreIdandlistUser`;

/*survey - group*/
export const listSurveyGroup = `${SERVICE_URI}/api/surveygroup/list`;
export const listSurveyGroupFull = `${SERVICE_URI}/api/surveygroup/listfull`;
export const createSurveyGroup = `${SERVICE_URI}/api/surveygroup/create`;
export const updateSurveyGroup = `${SERVICE_URI}/api/surveygroup/update`;
export const detailSurveyGroup = `${SERVICE_URI}/api/surveygroup/detail`;
export const deleteSurveyGroup = `${SERVICE_URI}/api/surveygroup/del`;

/*screen*/
export const listScreen = `${SERVICE_URI}/api/screen/list`;
export const listScreenPage = `${SERVICE_URI}/api/screen/listpage`;
export const createScreen = `${SERVICE_URI}/api/screen/create`;
export const updateScreen = `${SERVICE_URI}/api/screen/update`;
export const detailScreen = `${SERVICE_URI}/api/screen/detail`;
export const deleteScreen = `${SERVICE_URI}/api/screen/del`;
export const updateBtnSend = `${SERVICE_URI}/api/screen/updatebtnsend`;

export const listScreenType = `${SERVICE_URI}/api/screen/listtype`;

/*input*/
export const listInputInScreen = `${SERVICE_URI}/api/input/listinscreen`;
export const listInputInSurvey = `${SERVICE_URI}/api/input/listinsurvey`;
export const createInput = `${SERVICE_URI}/api/input/create`;
export const updateInput = `${SERVICE_URI}/api/input/update`;
export const detailInput = `${SERVICE_URI}/api/input/detail`;
export const deleteInput = `${SERVICE_URI}/api/input/del`;
export const sortInputInScreen = `${SERVICE_URI}/api/input/sortinputinscreen`;


/*user*/
export const listUser = `${SERVICE_URI}/api/user/listuser`;
export const createUserCompany = `${SERVICE_URI}/api/user/registerusercompany`;
export const detailUserCompany = `${SERVICE_URI}/api/user/detailuserincompany`;
export const detailCompanyProject = `${SERVICE_URI}/api/project/listbyusertotal`;
export const updateUserCompany = `${SERVICE_URI}/api/user/updateusercompany`;
export const deleteUserCompany = `${SERVICE_URI}/api/user/delusercompany`;


/*dropdown*/
export const listRoleCompanyDropdown = `${SERVICE_URI}/api/rolecompany/listfull`;
export const listRoleProjectDropdown = `${SERVICE_URI}/api/roleproject/listfull`;
export const listTypeSurveyDropdown = `${SERVICE_URI}/api/survey/listtype`;
export const listColumnTypeDropdown = `${SERVICE_URI}/api/columntype/liststore`;
export const listColumnSurveyDropdown = `${SERVICE_URI}/api/columnname/listsurveydata`;
export const listInputTypeDropdown = `${SERVICE_URI}/api/inputtype/list`;
export const listColumnNameStoreDropdown = `${SERVICE_URI}/api/columnname/liststore`;
export const listProjectStatusDropdown = `${SERVICE_URI}/api/project/liststatus`;
export const listUserProjectDropdown = `${SERVICE_URI}/api/user/usersproject`;
export const listProductDropdown = `${SERVICE_URI}/api/product/listfull`;
export const listAITypeDropdown = `${SERVICE_URI}/api/typeai/list`;
export const listAIInputDropdown = `${SERVICE_URI}/api/input/listcanuseai`;
export const listAIInputProjectDropdown = `${SERVICE_URI}/api/input/listinproject`;
export const listAIProductDropdown = `${SERVICE_URI}/api/product/listcanuseai`;
export const listProductGroupDropdown = `${SERVICE_URI}/api/productgroup/listfull`;
export const listCompanyDropdown = `${SERVICE_URI}/api/company/listfull`;
export const listCompanyProductDropdown = `${SERVICE_URI}/api/product/listfullincompany`;
export const listUserRoleProjectFull = `${SERVICE_URI}/api/userroleproject/listfull`;

/*config*/
export const configRoleCompany = `${SERVICE_URI}/api/rolecompany/listdataconfig`;
export const configRoleProject = `${SERVICE_URI}/api/roleproject/listdataconfig`;

/*config column store*/
export const listConfigColumnStore = `${SERVICE_URI}/api/configcolumnstore/list`;
export const createConfigColumnStore = `${SERVICE_URI}/api/configcolumnstore/create`;
export const updateConfigColumnStore = `${SERVICE_URI}/api/configcolumnstore/update`;
export const detailConfigColumnStore = `${SERVICE_URI}/api/configcolumnstore/detail`;
export const deteteConfigColumnStore = `${SERVICE_URI}/api/configcolumnstore/del`;

/*store-user*/
export const listStoreUser = `${SERVICE_URI}/api/storeuser/list`;
export const createStoreUserList = `${SERVICE_URI}/api/storeuser/createlist`;
export const createStoreUser = `${SERVICE_URI}/api/storeuser/create`;
export const updateStoreUser = `${SERVICE_URI}/api/storeuser/update`;
export const detailStoreUser = `${SERVICE_URI}/api/storeuser/detail`;
export const deleteStoreUser = `${SERVICE_URI}/api/storeuser/del`;
export const listStoreUserNotAssign = `${SERVICE_URI}/api/store/liststoreusernotexistinproject`;
export const listStoreUser_Users_TotaStore = `${SERVICE_URI}/api/storeuser/listuserandtotalstore`;
export const listGroupByUser = `${SERVICE_URI}/api/storeuser/groupbyuser`;

export const assignAutoAll = `${SERVICE_URI}/api/storeuser/autoall`;
export const assignAutoStoreAndUser = `${SERVICE_URI}/api/storeuser/assignautostoreanduser`;

// export const detailUserCompany = `${SERVICE_URI}/api/user/detailuserincompany`;
// export const updateUserCompany = `${SERVICE_URI}/api/user/updateusercompany`;
// export const deleteUserCompany = `${SERVICE_URI}/api/user/delusercompany`;
export const listExistUserStore = `${SERVICE_URI}/api/storeuser/listuserinstorefull`;

export const assignStoreUserMultiple = `${SERVICE_URI}/api/storeuser/createmultiple`;


/*product*/

/*octopus*/
export const listProductOctopus = `${SERVICE_URI}/api/product/list`;

/*company*/
export const listProductCompany = `${SERVICE_URI}/api/product/listincompany`;
export const createProduct = `${SERVICE_URI}/api/product/create`;
export const detailProduct = `${SERVICE_URI}/api/product/detail`;
export const detailProductInCompany = `${SERVICE_URI}/api/product/detailincompany`;
export const detailProductAll = `${SERVICE_URI}/api/product/detailall`;
export const updateProduct = `${SERVICE_URI}/api/product/update`;
export const deleteProduct = `${SERVICE_URI}/api/product/del`;
export const assignProduct = `${SERVICE_URI}/api/product/assignproduct`;
export const deleteAssignProduct = `${SERVICE_URI}/api/product/delassignproduct`;





/*product-group*/
export const listProductGroup = `${SERVICE_URI}/api/productgroup/list`;
export const createProductGroup = `${SERVICE_URI}/api/productgroup/create`;
export const updateProductGroup = `${SERVICE_URI}/api/productgroup/update`;
export const detailProductGroup = `${SERVICE_URI}/api/productgroup/detail`;
export const deleteProductGroup = `${SERVICE_URI}/api/productgroup/del`;


/*product-project*/
export const listProductProject = `${SERVICE_URI}/api/productproject/list`;
export const createProductProject = `${SERVICE_URI}/api/productproject/create`;
export const detailProductProject = `${SERVICE_URI}/api/productproject/detail`;
export const updateProductProject = `${SERVICE_URI}/api/productproject/update`;
export const deleteProductProject = `${SERVICE_URI}/api/productproject/del`;

/*config A.I survey*/
export const listSurveyConfigAI = `${SERVICE_URI}/api/configaiinput/listinsurvey`;
export const listSurveyConfigAIProject = `${SERVICE_URI}/api/configaiinput/listinproject`;
export const createSurveyConfigAI = `${SERVICE_URI}/api/configaiinput/create`;
export const detailSurveyConfigAI = `${SERVICE_URI}/api/configaiinput/detail`;
export const updateSurveyConfigAI = `${SERVICE_URI}/api/configaiinput/update`;
export const deleteSurveyConfigAI = `${SERVICE_URI}/api/configaiinput/del`;

/*A.I*/
export const detectFileAI = `${SERVICE_URI}/api/ai/detailfile`;
export const callTrainingAI = `${SERVICE_URI}/api/ai/calltrain`;

/*NOTIFICATION  */
export const createNotification = `${SERVICE_URI}/api/notification/create`;
export const updateNotification = `${SERVICE_URI}/api/notification/update`;
export const detailNotification = `${SERVICE_URI}/api/notification/detail`;
export const delNotification = `${SERVICE_URI}/api/notification/del`;
export const listNotificationInCompany = `${SERVICE_URI}/api/notification/listincompany`;
export const listNotificationInProject = `${SERVICE_URI}/api/notification/listinproject`;
export const createNotificationInProject = `${SERVICE_URI}/api/notification/createinproject`;
export const updateNotificationInProject = `${SERVICE_URI}/api/notification/updateinproject`;
export const detailNotificationInProject = `${SERVICE_URI}/api/notification/detailinproject`;
export const delNotificationInProject = `${SERVICE_URI}/api/notification/delinproject`;
export const listNotificationByUser = `${SERVICE_URI}/api/notification/listnoti`;
export const readNotification = `${SERVICE_URI}/api/notification/readnotification`;


/**TEMPLATE */
export const templateImportUserInCompany = `${SERVICE_URI}/api/download/templateuser`;
export const templateDownloadImportStore = `${SERVICE_URI}/api/download/templatestore`;
export const templateDownloadImportDelStore = `${SERVICE_URI}/api/download/templatedelstore`;
export const templateDownloadImportStoreUser = `${SERVICE_URI}/api/download/templatestoreuser`;

/**REPORT */
export const Import_Store = `${SERVICE_URI}/api/import/importstore`;
export const Import_Store_User = `${SERVICE_URI}/api/import/storeuser`;
export const Import_User_Company = `${SERVICE_URI}/api/import/usercompany`;
export const Import_User_Project = `${SERVICE_URI}/api/import/userproject`;
export const Import_Update_Store = `${SERVICE_URI}/api/import/updatestore`;
export const Import_Delete_Store = `${SERVICE_URI}/api/import/deletestore`;
export const Download_Store_List = `${SERVICE_URI}/api/export/download`;


/*about*/
export const createAbout = `${SERVICE_URI}/api/about/create`;
export const updateAbout = `${SERVICE_URI}/api/about/update`;
export const detailAbout = `${SERVICE_URI}/api/about/detail`;
export const deleteAbout = `${SERVICE_URI}/api/about/del`;
export const listAboutAdmin = `${SERVICE_URI}/api/about/listadmin`;
export const listAbout = `${SERVICE_URI}/api/about/list`;
export const detailArtical = `${SERVICE_URI}/api/about/detailalias`;

/**CONFIG INPUT SHOW IN SURVEY */
export const createConfigInputInSurvey = `${SERVICE_URI}/api/configshowinputsurveydata/create`;
export const updateConfigInputInSurvey = `${SERVICE_URI}/api/configshowinputsurveydata/update`;
export const detailConfigInputInSurvey = `${SERVICE_URI}/api/configshowinputsurveydata/detail`;
export const deleteConfigInputInSurvey = `${SERVICE_URI}/api/configshowinputsurveydata/del`;
export const configViewInSurvey = `${SERVICE_URI}/api/configshowinputsurveydata/viewconfig`;


/*user*/
export const getUserInfo = `${SERVICE_URI}/api/user/profile`;

/**PERMISSION */
export const apiGetPermissionCompany = `${SERVICE_URI}/api/permission/company`;
export const apiGetPermissionProject = `${SERVICE_URI}/api/permission/project`;

/*Verified*/
// export const apiGetPermissionProject = `${SERVICE_URI}/api/permission/project`;
// export const apiGetPermissionCompany = `${SERVICE_URI}/api/permission/company`;
// export const apiGetPermissionProject = `${SERVICE_URI}/api/permission/project`;

/*address*/
export const getListAddress = `${SERVICE_URI}/api/province/listfull`;
export const getListProjectByUser = `${SERVICE_URI}/api/project/listbyuser`;
export const detailVerified = `${SERVICE_URI}/api/user/detail`;
export const verifiedAccount = `${SERVICE_URI}/api/user/validateuser`;


/*recruit*/
export const listRecruit = `${SERVICE_URI}/api/recruit/list`;
export const listRecruitProject = `${SERVICE_URI}/api/recruit/listinproject`;
export const detailRecruit = `${SERVICE_URI}/api/recruit/detail`;
export const detailRecruitHistory = `${SERVICE_URI}/api/recruit/history`;
export const createRecruit = `${SERVICE_URI}/api/recruit/create`;
export const listRecruitStatus = `${SERVICE_URI}/api/recruit/liststatus`;
export const listAuditor = `${SERVICE_URI}/api/auditor/list`;
export const assignAuditor = `${SERVICE_URI}/api/storeuser/assignauditor`;
export const updateRecruitStatus = `${SERVICE_URI}/api/recruit/updatestatus`;
export const listUserAuditor = `${SERVICE_URI}/api/user/listauditor`;
export const createAuditor = `${SERVICE_URI}/api/auditor/create`;

export const listUserByStoreId = `${SERVICE_URI}/api/storeuser/listuserbystore`;
export const listStoreByUserId = `${SERVICE_URI}/api/storeuser/liststorebyuser`;

export const checkPhoneExist = `${SERVICE_URI}/api/user/validatephoneuser`;


/**EXPORT */
export const downdloadReportSurvey = `${SERVICE_URI}/api/export/reportsurvey`;
export const downdloadModuleReport = `${SERVICE_URI}/api/export/modulereport`;
export const downdloadData = `${SERVICE_URI}/api/export/download`;

/*Config*/
export const listConfigFilter = `${SERVICE_URI}/api/configfilter/list`;
export const listSurveyByStore = `${SERVICE_URI}/api/configfilter/listsurveybystore`;
export const listFilterInPage = `${SERVICE_URI}/api/configfilter/listfilterinpage`;
export const listFilterDataType = `${SERVICE_URI}/api/configfilter/listdatatype`;
export const listFilterType = `${SERVICE_URI}/api/configfilter/listtype`;
export const listOptionColumnStore = `${SERVICE_URI}/api/configfilter/listoptioncolumnstore`;
export const addFilter = `${SERVICE_URI}/api/configfilter/create`;
export const updateFilter = `${SERVICE_URI}/api/configfilter/update`;
export const detailFilter = `${SERVICE_URI}/api/configfilter/detail`;
export const delFilter = `${SERVICE_URI}/api/configfilter/del`;
export const listFilterColumnStore = `${SERVICE_URI}/api/configfilter/listcolumnstore`;
export const listFilterStore = `${SERVICE_URI}/api/configfilter/listfilterstore`;

/*Duyet-khao-sat*/
export const listConfigConfirmSurvey = `${SERVICE_URI}/api/configconfirmsurvey/list`;
export const detailConfigConfirmSurvey = `${SERVICE_URI}/api/configconfirmsurvey/detail`;
export const createConfigConfirmSurvey = `${SERVICE_URI}/api/configconfirmsurvey/create`;
export const updateConfigConfirmSurvey = `${SERVICE_URI}/api/configconfirmsurvey/update`;
export const delConfigConfirmSurvey = `${SERVICE_URI}/api/configconfirmsurvey/del`;
export const listConfigConfirmSurveyProject = `${SERVICE_URI}/api/configconfirmsurvey/listinproject`;


/*Duyet-cua-hang*/
export const listSurveyConfirm = `${SERVICE_URI}/api/surveyconfirm/list`;
export const createSurveyConfirm = `${SERVICE_URI}/api/surveyconfirm/create`;
export const updateSurveyConfirm = `${SERVICE_URI}/api/surveyconfirm/update`;
export const detailSurveyConfirm = `${SERVICE_URI}/api/surveyconfirm/detail`;
export const delSurveyConfirm = `${SERVICE_URI}/api/surveyconfirm/del`;
export const listSurveyConfirmProject = `${SERVICE_URI}/api/surveyconfirm/listinproject`;
export const listSurveyConfirmTypeShow = `${SERVICE_URI}/api/surveyconfirm/typeshow`;

export const approveSurveyData = `${SERVICE_URI}/api/surveydata/confirmsurvey`;

//
export const listCategoryCompany = `${SERVICE_URI}/api/category/listincompany`;
export const detailCategory = `${SERVICE_URI}/api/category/detail`;
export const detailCompanyCategory = `${SERVICE_URI}/api/category/detailincompany`;
export const createCategory = `${SERVICE_URI}/api/category/create`;
export const delCategory = `${SERVICE_URI}/api/category/del`;
export const updateCategory = `${SERVICE_URI}/api/category/update`;
export const listCategoryDropdown = `${SERVICE_URI}/api/category/listallincompany`;

//upload
export const uploadMultipleTime = `${SERVICE_URI}/api/multipleanddate`;
export const uploadMultiple = `${SERVICE_URI}/api/multiple`;
export const fileUploadType = `${SERVICE_URI}/api/input/filetype`;
export const validateOctopus = `${SERVICE_URI}/api/user/validateoctopus`;

export const listModuleReportProject = `${SERVICE_URI}/api/modulereport/listinproject`;
export const listColumnReport = `${SERVICE_URI}/api/configfiltermodulereport/listcolumn`;
export const listModuleReport = `${SERVICE_URI}/api/modulereport/list`;
export const syncOptionsReport = `${SERVICE_URI}/api/configfiltermodulereport/listoptioncolumn`;
export const addModuleReport = `${SERVICE_URI}/api/modulereport/create`;
export const updateModuleReport = `${SERVICE_URI}/api/modulereport/update`;
export const detailModuleReport = `${SERVICE_URI}/api/modulereport/detail`;
export const delModuleReport = `${SERVICE_URI}/api/modulereport/del`;

export const link_social = `${SERVICE_URI}/api/user/link-social`;
export const unlink_social = `${SERVICE_URI}/api/user/unlink-social`;
export const check_exist_social = `${SERVICE_URI}/api/social-exists`;
export const login_social = `${SERVICE_URI}/api/login-social`;
export const register_social = `${SERVICE_URI}/api/register-social`;

export const dashboard_store_and_survey = `${SERVICE_URI}/api/dashboard/storeandsurvey`;

/*news*/
export const list_news = `${SERVICE_URI}/api/news/list`;
export const list_news_company = `${SERVICE_URI}/api/news/listincompany`;
export const create_news = `${SERVICE_URI}/api/news/create`;
export const update_news = `${SERVICE_URI}/api/news/update`;
export const detail_news = `${SERVICE_URI}/api/news/detail`;
export const del_news = `${SERVICE_URI}/api/news/del`;
export const detail_new_company = `${SERVICE_URI}/api/news/detailincompany`;
export const get_list_news = `${SERVICE_URI}/api/news/listnews`;

export const category_octopus = `${SERVICE_URI}/api/category/listallincoctopus`;
export const news_by_category = `${SERVICE_URI}/api/news/category`;
export const list_category_octopus = `${SERVICE_URI}/api/category/list`;
export const del_survey_group_data = `${SERVICE_URI}/api/surveydata/delsurveygroup`;

/*contact*/
export const listDepartmentDropdown = `${SERVICE_URI}/api/contact/listdepartment`;
export const list_contact = `${SERVICE_URI}/api/contact/list`;
export const createContact = `${SERVICE_URI}/api/contact/create`;
export const updateContact = `${SERVICE_URI}/api/contact/update`;
export const detailContact = `${SERVICE_URI}/api/contact/detail`;
export const deleteContact = `${SERVICE_URI}/api/contact/del`;

export const detail_news_alias = `${SERVICE_URI}/api/news/detailalias`;

export const list_wait_auth = `${SERVICE_URI}/api/user/listwaitauth`;

/**QR CODE */
export const generate_qrcode = `${SERVICE_URI}/api/qrcode/generate`;

/**LOAD PAGE */
export const LIST_PAGE_CUSTOM = `${SERVICE_URI}/api/pagecustom/listpage`;
export const DETAIL_PAGE_CUSTOM = `${SERVICE_URI}/api/pagecustom/detailpage`;
export const DATA_PAGE_CUSTOM = `${SERVICE_URI}/api/pagecustom/datapage`;

/**MODULE DASHBOARD */

export const MODULE_DASHBOARD_URL = `${SERVICE_URI}`;
export const MODULE_DASHBOARD_LIST_INPROJECT = `${SERVICE_URI}/api/dashboardcustom/listdashboard`;