import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2'

const qrcode_ini = () => {
    return {
        type: types.QRCODE_INI
    };
};

const qrcode_success = (detail) => {
    return {
        type: types.QRCODE_SUCCESS,
        detail
    }
}

const qrcode_loading = (loading) => {
    return {
        type: types.QRCODE_LOADING,
        loading
    }
}

const gerenateQRCode = (objAdd) => (dispatch) => {
    dispatch(qrcode_loading(true));
    axiosWrapper.axiosWithHeaders("post", `${api.generate_qrcode}`, objAdd)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    const { result } = data;
                    dispatch(qrcode_success(JSON.stringify(result)))
                } else {
                    Swal.fire({
                        title: 'Tạo mã QR code',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Tạo mã QR code',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Tạo mã QR code',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(qrcode_loading(false));
        })
}

export {
    gerenateQRCode
}