import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2'


const addNews = (objAdd, updateEnd) => {
    console.log(updateEnd)
    axiosWrapper.axiosWithHeaders("post", api.create_news, objAdd)
        .then(response => {
            if (response) {
                if (response.status === status.STATUS_RESPONSE_OK) {
                    const {
                        data
                    } = response;
                    if (data.status === status.STATUS_RESPONSE_OK) {
                        Swal.fire({
                            title: 'Thêm mới',
                            text: 'Tạo tin tức thành công !',
                            imageUrl: '/img/octopus/success.svg',
                            imageWidth: 109,
                            imageHeight: 98,
                            imageAlt: 'Success',
                            showConfirmButton: false,
                            timer: 3000,
                            showCloseButton: true
                        })
                        updateEnd();
                    } else {
                        Swal.fire({
                            title: 'Thêm mới',
                            text: data.message,
                            imageUrl: '/img/octopus/error.svg',
                            imageWidth: 109,
                            imageHeight: 98,
                            imageAlt: 'Error',
                            confirmButtonColor: '#76cc68',
                            showCloseButton: true
                        })
                    }
                } else {
                    Swal.fire({
                        title: 'Thêm mới',
                        text: response.statusText,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Thêm mới',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
}

const detailNews = (id) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.detail_news}`, { _id: id });
}

const detailNewCompany = (objDetail) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.detail_new_company}`, objDetail);
}

const getListNews = () => {
    return axiosWrapper.axiosWithHeadersNoToken("post", `${api.get_list_news}`, {});
}

const updateNews = (objUpdate, updateEnd) => {
    axiosWrapper.axiosWithHeaders("post", api.update_news, objUpdate)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Cập nhật tin tức',
                        text: 'Cập nhật tin tức thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Cập nhật tin tức',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Cập nhật tin tức',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Cập nhật tin tức',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
            })
        })
}

const deleteNews = (objDel, updateEnd) => {
    axiosWrapper.axiosWithHeaders("post", api.del_news, objDel)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Xóa',
                        text: 'Xóa tin tức thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Xóa',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Xóa',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Xóa',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
            })
        })
}

export {
    addNews,
    detailNews,
    updateNews,
    deleteNews,
    detailNewCompany,
    getListNews
}