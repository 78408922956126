import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2';

const createConfirmConfigSurvey = (objAdd, updateEnd) => {   
    axiosWrapper.axiosWithHeaders("post", api.createConfigConfirmSurvey, objAdd)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Cấu hình',
                        text: 'Thêm mới cấu hình thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Cấu hình',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Cấu hình',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Cấu hình',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
}

const updateConfirmConfigSurvey = (objUpdate, updateEnd) => {   
    axiosWrapper.axiosWithHeaders("post", api.updateConfigConfirmSurvey, objUpdate)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Cấu hình',
                        text: 'Cập nhật cấu hình thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Cấu hình',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Cấu hình',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Cấu hình',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
}


const delConfirmConfigSurvey = (objDel, updateEnd) => {   
    axiosWrapper.axiosWithHeaders("post", api.delConfigConfirmSurvey, objDel)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Cấu hình',
                        text: 'Xóa cấu hình thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Cấu hình',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Cấu hình',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Cấu hình',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
}

const detailConfirmConfigSurvey = (objDetail) => {   
    return axiosWrapper.axiosWithHeaders("post", api.detailConfigConfirmSurvey, objDetail)
}

export {
    createConfirmConfigSurvey,
    updateConfirmConfigSurvey,
    delConfirmConfigSurvey,
    detailConfirmConfigSurvey
}