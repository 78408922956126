import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import * as status from '@/common/enum';
import Swal from 'sweetalert2';

const postData = (apiLink, objParam) => {
    return axiosWrapper.axiosWithHeaders("post", apiLink, objParam)
}

export {
    postData
}