import * as types from '@/redux/contains/types';
import * as enums from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2';

const store_detail_ini = () => {
    return {
        type: types.STORE_INI
    };
};

const store_detail_success = (detail) => {
    return {
        type: types.STORE_SUCCESS,
        detail
    }
}

const store_extracolumns_success = (extracolumns) => {
    return {
        type: types.STORE_EXTRA_COLUMN_SUCCESS,
        extracolumns
    }
}

const store_detail_loading = (loading) => {
    return {
        type: types.STORE_LOADING,
        loading
    }
}

const checkStoreCode = (storeCode, projectId) => {
    return axiosWrapper.axiosWithHeaders("post", api.checkStoreCode, { storeCode, projectId });
}

const addStore = (objAdd, updateEnd) => {
    axiosWrapper.axiosWithHeaders("post", api.createStore, objAdd)
        .then(response => {
            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data: {
                        status,
                        message
                    }
                } = response;
                if (status === enums.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Thêm mới',
                        text: 'Tạo địa điểm thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    console.log(`error add store: ${message}`);
                }
            }
        })
        .catch((err) => {
            console.log(`error add store: ${err.toString()}`);
        })
}

const detailStore = (storeId, companyId, projectId) => {
    return axiosWrapper.axiosWithHeaders("post", api.detailStore, { storeId, companyId, projectId })
}

const getExtraColumnStore = (companyId, projectId) => {
    return axiosWrapper.axiosWithHeaders("post", api.listColumnStore, { companyId, projectId })
}

const updateStore = (objUpdate, updateEnd) => {
    axiosWrapper.axiosWithHeaders("post", api.updateStore, objUpdate)
        .then(response => {
            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data: {
                        status,
                        message
                    }
                } = response;
                if (status === enums.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Cập nhật',
                        text: 'Cập nhật địa điểm thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    console.log(`error update store: ${message}`);
                }
            }
        })
        .catch((err) => {
            console.log(`error update store: ${err.toString()}`);
        })
}

const deleteStore = (storeId, companyId, projectId, updateEnd) => {
    axiosWrapper.axiosWithHeaders("post", api.deleteStore, { storeId, companyId, projectId })
        .then(response => {
            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data: {
                        status,
                        message
                    }
                } = response;
                if (status === enums.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Xóa',
                        text: 'Xóa địa điểm thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    console.log(`error delete store: ${message}`);
                }
            }
        })
        .catch((err) => {
            console.log(`error delete store: ${err.toString()}`);
        })
}

const deleteListUserStore = (objDelete, updateEnd) => {
    axiosWrapper.axiosWithHeaders("post", api.deleteListUserStore, objDelete)
        .then(response => {
            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data: {
                        status,
                        message
                    }
                } = response;

                if (status === enums.STATUS_RESPONSE_OK) {
                    Swal.fire("Thông báo", "Xóa phân bổ thành công", "success");
                } else {
                    Swal.fire("Lỗi", message, "error");
                }
            }
        })
        .catch((err) => {
            console.log(`Lỗi xóa danh sách nhân viên đã phân bổ vào địa điểm`, err.toString());
        })
        .finally(() => {
            updateEnd();
        })
}

export {
    addStore,
    detailStore,
    updateStore,
    deleteStore,
    checkStoreCode,
    getExtraColumnStore,
    deleteListUserStore
}