import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2';


const product_project_success = (detail) => {
    return {
        type: types.PRODUCT_PROJECT_SUCCESS,
        detail
    }
}

const product_project_loading = (loading) => {
    return {
        type: types.PRODUCT_PROJECT_LOADING,
        loading
    }
}

const addProductProject = (objAdd, updateEnd) => (dispatch) => {
    dispatch(product_project_loading(true))
    axiosWrapper.axiosWithHeaders("post", api.createProductProject, objAdd)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Sản phẩm',
                        text: 'Tạo Sản phẩm thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })                    
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Sản phẩm',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Sản phẩm',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Sản phẩm',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(product_project_loading(false));
        })
}

const detailProductProject = (id) => (dispatch) => {
    dispatch(product_project_loading(true));
    axiosWrapper.axiosWithHeaders("get", `${api.detailProductProject}?id=${id}`, null)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    const { result } = data;
                    dispatch(product_project_success(result))
                } else {
                    Swal.fire({
                        title: 'Chi tiết Sản phẩm',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Chi tiết Sản phẩm',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Chi tiết Sản phẩm',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(product_project_loading(false));
        })
}

const updateProductProject = (objUpdate, updateEnd) => (dispatch) => {
    dispatch(product_project_loading(true))
    axiosWrapper.axiosWithHeaders("post", api.updateProductProject, objUpdate)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Cập nhật',
                        text: 'Cập nhật Sản phẩm thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })           
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Cập nhật Sản phẩm',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Cập nhật Sản phẩm',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Cập nhật Sản phẩm',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(product_project_loading(false));
        })
}

const deleteProductProject = (productId, projectId, updateEnd) => (dispatch) => {
    axiosWrapper.axiosWithHeaders("post", api.deleteProductProject, { productId, projectId })
    .then(response => {
        if (response.status === status.STATUS_RESPONSE_OK && response.data) {
            const {
                data
            } = response;
            if (data.status === status.STATUS_RESPONSE_OK) {
                Swal.fire({
                    title: 'Xóa sản phẩm',
                    text: 'Xóa Sản phẩm thành công !',
                    imageUrl: '/img/octopus/success.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Success',
                    showConfirmButton: false,
                    timer: 3000,
                    showCloseButton: true
                })      
                updateEnd();
            } else {
                Swal.fire({
                    title: 'Xóa Sản phẩm',
                    text: data.message,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        } else {
            Swal.fire({
                title: 'Xóa Sản phẩm',
                text: response.statusText,
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        }
    })
    .catch((err) => {
        Swal.fire({
            title: 'Xóa Sản phẩm',
            text: err.toString(),
            imageUrl: '/img/octopus/error.svg',
            imageWidth: 109,
            imageHeight: 98,
            imageAlt: 'Error',
            confirmButtonColor: '#76cc68',
            showCloseButton: true
        })
    })
}

export {
    addProductProject,
    detailProductProject,
    updateProductProject,
    deleteProductProject
}