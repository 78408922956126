import * as api from '@/common/api';
import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import Swal from 'sweetalert2';

const datatable_ini = () => {
    return {
        type: types.DATA_TABLE_INI
    };
};

const datatable_columns = (extracolumns) => {
    return {
        type: types.DATA_TABLE_EXTRA_COLUMN,
        extracolumns
    }
}

const datatable_values = (value) => {
    return {
        type: types.DATA_TABLE_VALUES,
        value
    }
}


const datatable_loading = (loading) => {
    return {
        type: types.DATA_TABLE_LOADING,
        loading
    }
}

const getGridDatav2 = (apiList, objFilt) => {
    // console.log(apiList, objFilt);
    return axiosWrapper.axiosWithHeaders("post", apiList, objFilt);
}

const getGridData = (apiList, objFilt) => (dispatch) => {
    dispatch(datatable_loading(true));
    axiosWrapper.axiosWithHeaders("post", apiList, objFilt)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    dispatch(datatable_values(data.result));
                } else {
                    Swal.fire({
                        title: 'Bảng dữ liệu',
                        text: data.message,
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Bảng dữ liệu',
                    text: response.statusText,
                    imageUrl: '/img/octopus/success.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Success',
                    showConfirmButton: false,
                    timer: 3000,
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Bảng dữ liệu',
                text: err.toString(),
                imageUrl: '/img/octopus/success.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Success',
                showConfirmButton: false,
                timer: 3000,
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(datatable_loading(false));
        })
}

const getExtraColumn = (apiExtraColumn, objectParam) => (dispatch) => {
    dispatch(datatable_loading(true));
    axiosWrapper.axiosWithHeaders("post", apiExtraColumn, objectParam)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    dispatch(datatable_columns(data.result));
                } else {
                    Swal.fire({
                        title: 'Cột dữ liệu',
                        text: data.message,
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Cột dữ liệu',
                    text: response.statusText,
                    imageUrl: '/img/octopus/success.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Success',
                    showConfirmButton: false,
                    timer: 3000,
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Cột dữ liệu',
                text: err.toString(),
                imageUrl: '/img/octopus/success.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Success',
                showConfirmButton: false,
                timer: 3000,
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(datatable_loading(false));
        })
}

const getExtraColumnv2 = (apiExtraColumn, objectParam) => {
    return axiosWrapper.axiosWithHeaders("post", apiExtraColumn, objectParam)
}

const getExtraColumnv3 = (objectParam) => {
    return axiosWrapper.axiosWithHeaders("post", api.listColumnStore, objectParam)
}

const getExtraColumnFilterStore = (objectParam) => {
    return axiosWrapper.axiosWithHeaders("post", api.listFilterColumnStore, objectParam)
}

export {
    getGridData,
    getGridDatav2,
    getExtraColumn,
    getExtraColumnv2,
    getExtraColumnv3,
    getExtraColumnFilterStore
}
