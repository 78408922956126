import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import { savePermissionCompany, savePermissionProject } from '@/common/function'
import Swal from 'sweetalert2';

const permission_ini = () => {
    return {
        type: types.PERMISSION_INI
    };
};

const permission_success_compamy = (detail, companyId) => {
    return {
        type: types.PERMISSION_SUCCESS_COMPANY,
        detail,
        companyId
    }
}

const permission_success_project = (detail, projectId) => {
    return {
        type: types.PERMISSION_SUCCESS_PROJECT,
        detail,
        projectId
    }
}

const permission_loading = (loading) => {
    return {
        type: types.PERMISSION_LOADING,
        loading
    }
}

const getPermissionCompany = (item, nextPage) => (dispatch) => {
    // console.log(companyId);
    dispatch(permission_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.apiGetPermissionCompany, { companyId: item.companyId })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    savePermissionCompany(data.result, item.companyId)
                    dispatch(permission_success_compamy(data.result, item.companyId));
                    nextPage(item);
                } else {
                    Swal.fire({
                        title: 'Phân quyền công ty',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Phân quyền công ty',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Phân quyền công ty',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(permission_loading(false));
        })
}

// const getPermissionProject = (companyId, projectId, nextPage) => (dispatch) => {
const getPermissionProject = (item, nextPage) => (dispatch) => {
    dispatch(permission_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.apiGetPermissionProject, { companyId: item.companyId, projectId: item.projectId })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    savePermissionProject(data.result, item.projectId)
                    dispatch(permission_success_project(data.result, item.projectId));                   
                    nextPage(item)
                } else {
                    Swal.fire({
                        title: 'Phân quyền dự án',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Phân quyền dự án',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Phân quyền dự án',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(permission_loading(false));
        })
}

export {
    getPermissionCompany,
    getPermissionProject
}
