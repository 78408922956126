import * as types from '../contains/types';

export const refreshtoken_run = () => {
    return {
        type: types.RUN_REFRESH_TOKEN
    };
};

export const refreshtoken_stop = () => {
    return {
        type: types.STOP_REFESH_TOKEN
    }
}

