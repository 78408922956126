import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2';

// const role_company_ini = () => {
//     return {
//         type: types.ROLE_COMPANY_INI
//     };
// };

const role_company_success = (list) => {
    return {
        type: types.ROLE_COMPANY_SUCCESS,
        list
    }
}

const role_company_detail = (detail) => {
    return {
        type: types.ROLE_COMPANY_DETAIL,
        detail
    }
}

// const cog_role_company_ini = () => {
//     return {
//         type: types.CONFIG_ROLE_COMPANY_INI
//     };
// };

const cog_role_company_success = (config) => {
    return {
        type: types.CONFIG_ROLE_COMPANY_SUCCESS,
        config
    }
}

const role_company_loading = (loadingList) => {
    return {
        type: types.ROLE_COMPANY_LOADING,
        loadingList
    }
}

const cog_role_company_loading = (loadingConfig) => {
    return {
        type: types.CONFIG_ROLE_COMPANY_LOADING,
        loadingConfig
    }
}

const getListRoleCompany = (companyId) => (dispatch) => {
    dispatch(role_company_loading(false));
    axiosWrapper.axiosWithHeaders("post", api.listRoleCompanyDropdown, { companyId })
    .then(response => {
        if (response.status === status.STATUS_RESPONSE_OK && response.data) {
            const {
                data
            } = response;
            if (data.status === status.STATUS_RESPONSE_OK) {
                dispatch(role_company_success(data.result));
            } else {
                Swal.fire({
                    title: 'Quyền truy cập công ty',
                    text: data.message,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        } else {
            Swal.fire({
                title: 'Quyền truy cập công ty',
                text: response.statusText,
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        }
    })
    .catch((err) => {
        Swal.fire({
            title: 'Quyền truy cập công ty',
            text: err.toString(),
            imageUrl: '/img/octopus/error.svg',
            imageWidth: 109,
            imageHeight: 98,
            imageAlt: 'Error',
            confirmButtonColor: '#76cc68',
            showCloseButton: true
        })
    })
    .finally(() => {
        dispatch(role_company_loading(false));
    })
}

const getConfigRoleCompany = () => (dispatch) => {
    dispatch(cog_role_company_loading(true));
    axiosWrapper.axiosWithHeaders("get", api.configRoleCompany, null)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    dispatch(cog_role_company_success(data.result));
                } else {
                    Swal.fire({
                        title: 'Cấu hình quyền truy cập công ty',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Cấu hình quyền truy cập công ty',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Cấu hình quyền truy cập công ty',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(cog_role_company_loading(false));
        })
}

const addRoleCompany = (objAdd, updateEnd) => (dispatch) => {
    dispatch(role_company_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.createRoleCompany, objAdd)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Tạo phân quyền',
                        text: 'Tạo phân quyền thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Tạo phân quyền',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Tạo phân quyền',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Tạo phân quyền',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(role_company_loading(false));
        })
}

const updateRoleCompany = (objUpdate, updateEnd) => (dispatch) => {
    dispatch(role_company_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.updateRoleCompany, objUpdate)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Cập nhật phân quyền',
                        text: 'Cập nhật phân quyền thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    });
                    updateEnd();                
                } else {
                    Swal.fire({
                        title: 'Cập nhật phân quyền',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Cập nhật phân quyền',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Cập nhật phân quyền',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(role_company_loading(false));
        })
}

const detailRoleCompany = (companyId, roleCompanyId) => (dispatch) => {
    dispatch(role_company_loading(true));
 //   console.log(companyId, roleCompanyId)
    axiosWrapper.axiosWithHeaders("post", `${api.detailRoleCompany}`, {companyId, roleCompanyId})
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    dispatch(role_company_detail(data.result))
                } else {
                    Swal.fire({
                        title: 'Chi tiết phân quyền',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Chi tiết phân quyền',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Chi tiết phân quyền',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(role_company_loading(false));
        })
}

const deleteRoleCompany = (roleCompanyId, updateEnd) => (dispatch) => {
    dispatch(role_company_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.deleteRoleCompany, { roleCompanyId })
        .then(response => {
            // console.log(response);
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Xóa phân quyền',
                        text: 'Xóa phân quyền thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    });
                    updateEnd();
                } 
                else {
                    Swal.fire({
                        title: 'Xóa phân quyền',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Xóa phân quyền',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Xóa phân quyền',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(role_company_loading(false));
        })
}

export {
    getListRoleCompany,
    getConfigRoleCompany,
    addRoleCompany,
    updateRoleCompany,
    detailRoleCompany,
    deleteRoleCompany
}
