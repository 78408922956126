import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2'


const survey_data_detail_ini = () => {
    return {
        type: types.SURVEY_DATA_DETAIL_INI
    };
};

const survey_data_detail_success = (store, listSurvey, listFile, listFileAI, listProduct) => {
    return {
        type: types.SURVEY_DATA_DETAIL_SUCCESS,
        store,
        listSurvey,
        listFile,
        listFileAI,
        listProduct
    }
}

const survey_data_detail_loading = (loading) => {
    return {
        type: types.SURVEY_DATA_DETAIL_LOADING,
        loading
    }
}

const detailSurveyData = (projectId, storeId) => (dispatch) => {
    dispatch(survey_data_detail_loading(true));
    axiosWrapper.axiosWithHeaders("post", `${api.detailSurveyData}`, { projectId, storeId })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    dispatch(survey_data_detail_success(data.store, data.listSurvey, data.listFile, data.listFileAI, data.listproducts));
                } else {
                    Swal.fire({
                        title: 'Dữ liệu khảo sát',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Dữ liệu khảo sát',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Dữ liệu khảo sát',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            const timer = setTimeout(() => {
                clearTimeout(timer);
                dispatch(survey_data_detail_loading(false));
            }, 1500)
        })
}

const detailSurveyDatav2 = (projectId, storeId) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.detailSurveyData}`, { projectId, storeId })
}

const detailSurveyDatav3 = (projectId, storeId) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.detailSurveyDatav2}`, { projectId, storeId })
}

const detailSurveyInDate = (projectId, storeId, dateSend) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.detailSurveyInDate}`, { projectId, storeId, dateSend })
}

const deleteSurveyData = (objDel, updateEnd) => (dispatch) => {
    dispatch(survey_data_detail_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.deleteSurveyData, objDel)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Dữ liệu khảo sát',
                        text: 'Xóa dữ liệu thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Dữ liệu khảo sát',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Dữ liệu khảo sát',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Dữ liệu khảo sát',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(survey_data_detail_loading(false));
        })
}

const approveUpdateSurvey = (objUpdate, updateEnd) => {
    axiosWrapper.axiosWithHeaders("post", api.approveUpdateSurvey, objUpdate)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Cập nhật',
                        text: 'Cập nhật dữ liệu khảo sát thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Cập nhật',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Cập nhật',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Cập nhật',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
}

const deleteSurveyGroupData = (objDel, updateEnd) => (dispatch) => {
    dispatch(survey_data_detail_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.del_survey_group_data, objDel)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Dữ liệu khảo sát',
                        text: 'Xóa dữ liệu thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Dữ liệu khảo sát',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Dữ liệu khảo sát',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Dữ liệu khảo sát',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(survey_data_detail_loading(false));
        })
}

export {
    detailSurveyData,
    detailSurveyDatav2,
    detailSurveyDatav3,
    detailSurveyInDate,
    deleteSurveyData,
    approveUpdateSurvey,
    deleteSurveyGroupData
}
