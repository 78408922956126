import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2'

const survey_group_success = (detail) => {
    return {
        type: types.SURVEY_GROUP_SUCCESS,
        detail
    }
}

const survey_group_options_success = (options) => {
    return {
        type: types.SURVEY_GROUP_OPTIONS_SUCCESS,
        options
    }
}

const survey_group_loading = (loading) => {
    return {
        type: types.SURVEY_GROUP_LOADING,
        loading
    }
}

const addSurveyGroup = (objAdd, updateEnd) => (dispatch) => {
    dispatch(survey_group_loading(true))
    axiosWrapper.axiosWithHeaders("post", api.createSurveyGroup, objAdd)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Thêm mới',
                        text: 'Tạo nhóm dữ liệu thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    console.log(`Thêm mới lỗi`, data.message)
                }
            } 
        })
        .catch((err) => {
            console.log(`error: ${err.toString()}`)
        })
        .finally(() => {
            dispatch(survey_group_loading(false));
        })
}

const detailSurveyGroup = (surveyGroupId) => (dispatch) => {
    dispatch(survey_group_loading(true));
    axiosWrapper.axiosWithHeaders("get", `${api.detailSurveyGroup}?surveyGroupId=${surveyGroupId}`, null)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    const { result } = data;
                    dispatch(survey_group_success(result))
                } else {
                    console.log(`Chi tiết lỗi`, data.message)
                }
            } 
        })
        .catch((err) => {
            console.log(`error: ${err.toString()}`)
        })
        .finally(() => {
            dispatch(survey_group_loading(false));
        })
}

const updateSurveyGroup = (objUpdate, updateEnd) => (dispatch) => {
    dispatch(survey_group_loading(true))
    axiosWrapper.axiosWithHeaders("post", api.updateSurveyGroup, objUpdate)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Cập nhật',
                        text: 'Cập nhật nhóm dữ liệu thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    console.log(`Cập nhật lỗi`, data.message)
                }
            } 
        })
        .catch((err) => {
            console.log(`Error`, err.toString())
        })
        .finally(() => {
            dispatch(survey_group_loading(false));
        })
}

const deleteSurveyGroup = (surveyGroupId, updateEnd) => (dispatch) => {
    axiosWrapper.axiosWithHeaders("post", api.deleteSurveyGroup, { surveyGroupId })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Xóa',
                        text: 'Xóa nhóm dữ liệu thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    console.log(`error: ${data.message}`)                   
                }
            } 
        })
        .catch((err) => {
            console.log(`error: ${err.toString()}`)
        })

}

const listSurveyGroupDropdown = (surveyId) => {   
    return axiosWrapper.axiosWithHeaders("post", api.listSurveyGroupFull, { surveyId })
}

export {
    addSurveyGroup,
    detailSurveyGroup,
    updateSurveyGroup,
    deleteSurveyGroup,
    listSurveyGroupDropdown
}