import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2';

const approveSurvey = (objApprove, updateEnd) => {
    axiosWrapper.axiosWithHeaders("post", api.approveSurveyData, objApprove)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Duyệt',
                        text: 'Duyệt thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Duyệt',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Duyệt',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Duyệt',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
}

export {
    approveSurvey
}