import * as types from '@/redux/contains/types';
// import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
// import Swal from 'sweetalert2';

// const project_status_ini = () => {
//     return {
//         type: types.PROJECT_STATUS_INI
//     };
// };

// const project_status_success = (options) => {
//     return {
//         type: types.PROJECT_STATUS_SUCCESS,
//         options
//     }
// }

// const project_status_loading = (loading) => {
//     return {
//         type: types.PROJECT_STATUS_LOADING,
//         loading
//     }
// }

const getListProjectStatus = () => {
    // dispatch(project_status_loading(true));
    return axiosWrapper.axiosWithHeaders("get", api.listProjectStatusDropdown, null)
        // .then(response => {
        //     if (response.status === status.STATUS_RESPONSE_OK && response.data) {
        //         const {
        //             data
        //         } = response;
        //         if (data.status === status.STATUS_RESPONSE_OK) {
        //             dispatch(project_status_success(data.result));
        //         } else {
        //             Swal.fire({
        //                 title: 'Trạng thái dự án',
        //                 text: data.message,
        //                 imageUrl: '/img/octopus/error.svg',
        //                 imageWidth: 109,
        //                 imageHeight: 98,
        //                 imageAlt: 'Error',
        //                 confirmButtonColor: '#76cc68',
        //                 showCloseButton: true
        //             })
        //         }
        //     } else {
        //         Swal.fire({
        //             title: 'Trạng thái dự án',
        //             text: response.statusText,
        //             imageUrl: '/img/octopus/error.svg',
        //             imageWidth: 109,
        //             imageHeight: 98,
        //             imageAlt: 'Error',
        //             confirmButtonColor: '#76cc68',
        //             showCloseButton: true
        //         })
        //     }
        // })
        // .catch((err) => {
        //     Swal.fire({
        //         title: 'Trạng thái dự án',
        //         text: err.toString(),
        //         imageUrl: '/img/octopus/error.svg',
        //         imageWidth: 109,
        //         imageHeight: 98,
        //         imageAlt: 'Error',
        //         confirmButtonColor: '#76cc68',
        //         showCloseButton: true
        //     })
        // })
        // .finally(() => {
        //     dispatch(project_status_loading(false));
        // })
}

export {
    getListProjectStatus,
}
