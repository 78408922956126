import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';

const detailRecruit = (recruitId) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.detailRecruit}`, { recruitId })
}

const detailRecruitHistory = (recruitId) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.detailRecruitHistory}`, { recruitId })
}

const listRecruitStatus = () => {
    return axiosWrapper.axiosWithHeaders("get", `${api.listRecruitStatus}`, null)
}

const createRecruit = (objAdd) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.createRecruit}`, objAdd)
}

const assignAuditor = (objAssign) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.assignAuditor}`, objAssign)
}

const updateRecruitStatus = (objUpdate) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.updateRecruitStatus}`, objUpdate)
}

const createAuditor = (objAdd) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.createAuditor}`, objAdd)
}

export {
    detailRecruit,
    detailRecruitHistory,
    createRecruit,
    listRecruitStatus,
    assignAuditor,
    updateRecruitStatus,
    createAuditor
}