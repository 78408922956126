/*auth*/
import {
    encryptLogin,
    fnLogin,
    fnLogout,
    fnLoginOtp,
    fnLoginSocial,
    sendOtp,
    registerUserOTP,
    updateProfile,
    updateVerified,
    checkUserExists,
    detailVerified,
    verifiedAccount,
    registerSocial
} from '@/redux/actions/auth';

import { 
    getListFilter,
    getListFilterType,
    getListFilterDataType,
    getListOptionFilter,
    addFilter,
    updateFilter,
    delFilter,
    detailFilter
} from '@/redux/actions/config/filter';

/*role-company*/
import {
    getListRoleCompany,
    getConfigRoleCompany,
    addRoleCompany,
    detailRoleCompany,
    updateRoleCompany,
    deleteRoleCompany
} from '@/redux/actions/config/role/company'

/*user-role company*/
import {
    addUserRoleCompany,
    updateUserRoleCompany,
    detailUserRoleCompany,
    deleteUserRoleCompany
} from '@/redux/actions/company/user-role';

/*user-role project*/
import {
    addUserRoleProject,
    addUserRoleProjectPhone,
    detailUserRoleProject,
    updateUserRoleProject,
    deleteUserRoleProject,
    getListUserRoleProject,
    GetUserProject,
    GetListUserRoleProject2
} from '@/redux/actions/project/user/role';

/*role-project*/
import {
    getListRoleProject,
    getConfigRoleProject,
    getConfigRoleProject2,
    detailRoleProject2,
    addRoleProject,
    detailRoleProject,
    updateRoleProject,
    deleteRoleProject
} from '@/redux/actions/config/role/project';

/*config store column*/
import {
    addConfigColumnStore,
    detailConfigColumnStore,
    updateConfigColumnStore,
    deleteConfigColumnStore
} from '@/redux/actions/config/store/column';

/*store column-type*/
import {
    getListColumnType
} from '@/redux/actions/config/store/column-type'

/*project-status*/
import {
    getListProjectStatus
} from '@/redux/actions/project/status';

/*user project*/
import {
    getListUserProject,
    GetListUserProject2
} from '@/redux/actions/project/user';

/*user company*/
import {
    addUserCompany,
    detailUserCompany,
    detailCompanyProject,
    updateUserCompany,
    deleteUserCompany,
    checkPhoneExist
} from '@/redux/actions/company/user';

/*project*/
import {
    addProject,
    detailProject,
    updateProject,
    deleteProject
} from '@/redux/actions/project';

/*survey-type*/
import {
    getListSurveyType
} from '@/redux/actions/survey/type';

/*survey*/
import {
    addSurvey,
    detailSurvey,
    deleteSurvey,
    updateSurvey,
    getListSurveyByProjectId,
    transferSurveyDataGroup
} from '@/redux/actions/survey';

/*store*/
import {
    addStore,
    updateStore,
    detailStore,
    deleteStore,
    checkStoreCode,
    getExtraColumnStore,
    deleteListUserStore
} from '@/redux/actions/store';

/*company*/
import {
    addCompany,
    detailCompany,
    updateCompany,
    deleteCompany,
    getListCompany,
    getListCompanyProduct,
    overviewCompany
} from '@/redux/actions/company';

/*screen*/
import {
    addScreen,
    detailScreen,
    updateScreen,
    deleteScreen,
    getListScreen,
    getListScreenv2,
    updateBtnSend,
    screen_detail_ini,
    getListScreenType
} from '@/redux/actions/screen';

/*input-screen*/
import {
    getListInputScreen,
    getListInputSurvey
} from '@/redux/actions/screen/input';

/*input*/
import {
    addInput,
    updateInput,
    detailInput,
    deleteInput,
    onChangeSortInput,
    listFileUploadType
} from '@/redux/actions/input';

/*survey-group*/
import {
    addSurveyGroup,
    detailSurveyGroup,
    deleteSurveyGroup,
    updateSurveyGroup,
    listSurveyGroupDropdown
} from '@/redux/actions/survey/group';

/*data-table*/
import {
    getExtraColumn,
    getExtraColumnv2,
    getExtraColumnFilterStore,
    getExtraColumnv3,
    getGridData,
    getGridDatav2
} from '@/redux/actions/data-table/index';

/*product*/
import {
    addProduct,
    updateProduct,
    detailProduct,
    detailAllProduct,
    deleteProduct,
    getListProduct,
    assignProduct,
    product_ini
} from '@/redux/actions/product';

/*product-group*/
import {
    addProductGroup,
    updateProductGroup,
    detailProductGroup,
    deleteProductGroup,
    getListProductGroup,
    product_group_ini
} from '@/redux/actions/product/group';

/*product-project*/
import {
    addProductProject,
    updateProductProject,
    detailProductProject,
    deleteProductProject,
} from '@/redux/actions/product/project';

/*input-type*/
import {
    getListInputType
} from '@/redux/actions/input/type';

/*ai-type*/
import {
    getListAIType
} from '@/redux/actions/ai/type';

/*ai-input*/
import {
    getListAIInput,
    getListAIInputProject
} from '@/redux/actions/ai/input';

/*ai-product*/
import {
    getListAIProduct
} from '@/redux/actions/ai/product';

/*survey-data*/
import {
    detailSurveyData,
    detailSurveyDatav2,
    detailSurveyDatav3,
    detailSurveyInDate,
    deleteSurveyData,
    deleteSurveyGroupData,
    approveUpdateSurvey
} from '@/redux/actions/survey/data';

/*ai-survey-config*/
import {
    addSurveyConfigAI,
    updateSurveyConfigAI,
    detailSurveyConfigAI,
    deleteSurveyConfigAI
} from '@/redux/actions/ai/survey-config'

/*A.I*/
import {
    detailFileAIDetect,
    callTraining
} from '@/redux/actions/ai/index';

/*store-user*/
import {
    addStoreUserSingle,
    addStoreUserMultiple
} from '@/redux/actions/store/user'

/*NOtification */
import {
    addNotification,
    updateNotification,
    deleteNotification,
    detailNotification,
    addNotificationInProject,
    updateNotificationInProject,
    detailNotificationInProject,
    deleteNotificationInProject, 
    listNotificationUser,
    readNotification
} from '@/redux/actions/notification/index';

/*Contact */
import {
    addContact,
    updateContact,
    detailContact,
    deleteContact,
    getListDepartment
} from '@/redux/actions/contact'

/**TEMPLATE */
import {
    templateUserInCompany,
    templateStoreUser,
    templateStore,
    importStore,
    importStoreUser,
    importUserInCompany,
    importUserInProject,
    downloadStore,
    templateDelStore
} from '@/redux/actions/template/index';

/*error*/
import {
    error_show
} from './error';

/*Status EXP TOKEN*/
import {
    refreshtoken_run,
    refreshtoken_stop
} from './statusExpToken';

/*about*/
import {
    addAbout,
    updateAbout,
    deleteAbouts,
    detailAbout,
    detailListAbout,
    detailArtical
} from './about';

/*configInputInsurrvey*/
import {
    getViewConfigInSurvey,
    addConfigInputInsuvey,
    updateConfigInputInsuvey,
    delConfigInputInsuvey
} from './survey/configInputShow';

import {
    windowSizeChange
} from './dimensions'

import {
    getUserInfo,
    getUserInfov2,
    validateOctopus
} from './account';

import {
    getPermissionCompany,
    getPermissionProject
} from './permission';

import {
    getListAddress
} from './address';

import {
    overviewProject
} from './project/overview';

/*recruit*/
import {
    detailRecruit,
    detailRecruitHistory,
    listRecruitStatus,
    createRecruit,
    assignAuditor,
    updateRecruitStatus,
    createAuditor
} from './recruit';

/*assign store user*/
import {
    assignAll,
    assignStoreAndUser,
    getExistUserStore,
    assignStoreUserMultiple,
} from './store/assign'

import {
    reportsurvey,
    modulereport
} from './export/index';

import {
    createConfirmConfigSurvey,
    updateConfirmConfigSurvey,
    delConfirmConfigSurvey,
    detailConfirmConfigSurvey
} from './confirm/survey';

import {
    createSurveyConfirm,
    updateSurveyConfirm,
    delSurveyConfirm,
    detailSurveyConfirm,
    listSurveyConfirmDropdown,
    listTypeShowDropdown,
} from './confirm/store';

import {
    approveSurvey
} from './approve';

import {
    addCategory,
    detailCategory,
    updateCategory,
    delCategory,
    detailCategoryCompany,
    listCategory,
    categoryOctopus,
    getListNewsByCategory
} from './category';

import { 
    uploadImageAndTime,
} from './upload';

import {
    moduleReportByProject,
    getListColumnByTable,
    onSyncOptionReport,
    addModuleReport,
    updateModuleReport,
    detailModuleReport,
    delModuleReport
} from './report';

import {
    linkSocial,
    unlinkSocial,
    checkSocialExists
} from './social'

import {
    dashboardStoreAndSurvey
} from './dashboard/project';

import {
    gerenateQRCode
} from './qrcode/index';
import {
    addNews,
    updateNews,
    deleteNews,
    detailNews,
    detailNewCompany,
    getListNews
} from './news'


import {
  postData
} from './apicall'
// import {
//     addCategory,
//     updateCategory,
//     delCategory,
//     detailCategory,
//     detailCategoryCompany,
// } from './category'

export {
    /*CALLAPI */
    postData,
    /*auth*/
    encryptLogin,
    fnLogin,
    fnLoginOtp,
    fnLogout,
    sendOtp,
    registerUserOTP,
    updateProfile,
    checkUserExists,
    /*role-company*/
    getListRoleCompany,
    getConfigRoleCompany,
    addRoleCompany,
    detailRoleCompany,
    updateRoleCompany,
    deleteRoleCompany,
    /*role-project*/
    getListRoleProject,
    getConfigRoleProject,
    getConfigRoleProject2,
    detailRoleProject2,
    addRoleProject,
    detailRoleProject,
    updateRoleProject,
    deleteRoleProject,
    /*project-status*/
    getListProjectStatus,
    /*user-company*/
    addUserCompany,
    detailUserCompany,
    updateUserCompany,
    deleteUserCompany,
    checkPhoneExist,
    /*project*/
    addProject,
    detailProject,
    updateProject,
    deleteProject,
    /*survey-type*/
    getListSurveyType,
    /*user-role company*/
    addUserRoleCompany,
    updateUserRoleCompany,
    detailUserRoleCompany,
    deleteUserRoleCompany,
    /*survey*/
    addSurvey,
    detailSurvey,
    deleteSurvey,
    updateSurvey,
    getListSurveyByProjectId,
    transferSurveyDataGroup,
    /*user project*/
    getListUserProject,
    GetListUserProject2,
    /*user-role project*/
    addUserRoleProject,
    addUserRoleProjectPhone,
    detailUserRoleProject,
    GetUserProject,
    GetListUserRoleProject2,
    updateUserRoleProject,
    deleteUserRoleProject,
    getListUserRoleProject,
    /*store column-type*/
    getListColumnType,
    /*config store column*/
    addConfigColumnStore,
    detailConfigColumnStore,
    updateConfigColumnStore,
    deleteConfigColumnStore,
    /*store*/
    addStore,
    updateStore,
    detailStore,
    deleteStore,
    checkStoreCode,
    getExtraColumnStore,
    deleteListUserStore,
    /*company*/
    addCompany,
    detailCompany,
    updateCompany,
    deleteCompany,
    getListCompany,
    getListCompanyProduct,
    overviewCompany,
    /*screen*/
    addScreen,
    detailScreen,
    updateScreen,
    deleteScreen,
    getListScreen,
    getListScreenv2,
    updateBtnSend,
    getListScreenType,
    screen_detail_ini,
    /*input-screen*/
    getListInputScreen,
    getListInputSurvey,
    /*survey-group*/
    addSurveyGroup,
    detailSurveyGroup,
    deleteSurveyGroup,
    updateSurveyGroup,
    listSurveyGroupDropdown,
    /*data-table*/
    getExtraColumn,
    getExtraColumnv2,
    getExtraColumnv3,
    getExtraColumnFilterStore,
    getGridData,
    getGridDatav2,
    /*product*/
    addProduct,
    updateProduct,
    detailProduct,
    deleteProduct,
    getListProduct,
    detailAllProduct,
    assignProduct,
    product_ini,
    /*product-project*/
    addProductProject,
    updateProductProject,
    detailProductProject,
    deleteProductProject,
    /*input*/
    addInput,
    updateInput,
    detailInput,
    onChangeSortInput,
    deleteInput,
    /*input-type*/
    getListInputType,
    /*ai-type*/
    getListAIType,
    /*ai-input*/
    getListAIInput,
    getListAIInputProject,
    /*ai-product*/
    getListAIProduct,
    /*ai-survey-config*/
    addSurveyConfigAI,
    updateSurveyConfigAI,
    detailSurveyConfigAI,
    deleteSurveyConfigAI,
    /*survey-data*/
    detailSurveyData,
    detailSurveyDatav2,
    detailSurveyDatav3,
    detailSurveyInDate,
    deleteSurveyData,
    approveUpdateSurvey,
    /*A.I*/
    detailFileAIDetect,
    callTraining,
    /*product group*/
    addProductGroup,
    updateProductGroup,
    detailProductGroup,
    deleteProductGroup,
    getListProductGroup,
    product_group_ini,
    /*store-user*/
    addStoreUserSingle,
    addStoreUserMultiple,
    /*Noitification */
    addNotification,
    updateNotification,
    deleteNotification,
    detailNotification,
    addNotificationInProject,
    updateNotificationInProject,
    detailNotificationInProject,
    deleteNotificationInProject,
    listNotificationUser,
    readNotification,
    /**TEMPLATE */
    templateUserInCompany,
    templateStoreUser,
    templateStore,
    importStore,
    importStoreUser,
    importUserInCompany,
    importUserInProject,
    downloadStore,
    templateDelStore,
    /*Contact */
    addContact,
    updateContact,
    detailContact,
    deleteContact,
    getListDepartment,
    /*show error*/
    error_show,
    /**Status Refresh token */
    refreshtoken_run,
    refreshtoken_stop,
    /*about*/
    addAbout,
    updateAbout,
    deleteAbouts,
    detailAbout,
    detailListAbout,
    detailArtical,

    /**COnfig Input in survey */
    getViewConfigInSurvey,
    addConfigInputInsuvey,
    updateConfigInputInsuvey,
    delConfigInputInsuvey,

    /*responsive*/
    windowSizeChange,
    /*info*/
    getUserInfo,
    /**PERMIssion */
    getPermissionCompany,
    getPermissionProject,

    /*address*/
    getListAddress,
    updateVerified,
    detailVerified,
    verifiedAccount,
    detailCompanyProject,

    /*overview project*/
    overviewProject,

    /*recruit*/
    detailRecruit,
    detailRecruitHistory,
    listRecruitStatus,
    createRecruit,
    assignAuditor,
    updateRecruitStatus,
    createAuditor,
    /*assign store user*/
    assignAll,
    assignStoreAndUser,
    getExistUserStore,
    assignStoreUserMultiple,
    /*Export */
    reportsurvey,
    modulereport,

    /*filter*/
    getListFilter,
    getListFilterType,
    getListFilterDataType,
    getListOptionFilter,
    addFilter,
    updateFilter,
    delFilter,
    detailFilter,
    /*confirm-config-survey*/
    createConfirmConfigSurvey,
    updateConfirmConfigSurvey,
    delConfirmConfigSurvey,
    detailConfirmConfigSurvey,

    ///*survey-confirm*/
    createSurveyConfirm,
    updateSurveyConfirm,
    delSurveyConfirm,
    detailSurveyConfirm,
    listSurveyConfirmDropdown,
    listTypeShowDropdown,
    approveSurvey,
    
    uploadImageAndTime,
    listFileUploadType,
    validateOctopus,
    moduleReportByProject,
    getListColumnByTable,
    onSyncOptionReport,
    addModuleReport,
    updateModuleReport,
    detailModuleReport,
    delModuleReport,
    getUserInfov2,
    linkSocial,
    unlinkSocial,
    checkSocialExists,
    fnLoginSocial,
    registerSocial,
    dashboardStoreAndSurvey,
    categoryOctopus,
    deleteSurveyGroupData,
    addNews,
    updateNews,
    deleteNews,
    detailNews,
    detailNewCompany,
    getListNews,

    addCategory,
    detailCategory,
    updateCategory,
    delCategory,
    detailCategoryCompany,
    listCategory,
    getListNewsByCategory,

    /**QR CODE */
    gerenateQRCode
}