import {
    deCryptToken,
    enCryptDataClient,
    deCryptRefreshToken
} from '@/common/Crypto';

import {
    REACT_APP_JWT,
    REACT_APP_JWT_REFESH,
    SERVICE_URI
} from '@/common/config';
// import { useSelector, useDispatch } from 'react-redux';
import * as actions from '@/redux/actions';
import * as api from '@/common/api';
import jwt from 'jsonwebtoken';

const axios = require('axios').default;
import Swal from 'sweetalert2';
import { currentStore } from '@/redux/reducers'
// import { atob } from 'abab';

// axios.interceptors.request.use((config) => {
//   // do something with the config
//   //kiểm tra token còn hạn sử dụng ko ?
//   let token =  config.headers['Authorization'] ;
//   console.log(JSON.stringify(token))
//   return config
// }, (err) => {
//   return Promise.reject(err.response)
// })

// const dispatch = useDispatch();
// const auth = useSelector((state) => state.auth);
axios.interceptors.response.use((response) => {
    return response
}, (err) => {
    // console.log(err);
    let isLoading = currentStore.getState().statusExpToken.isLoading
    //  console.log(isLoading)
    // Return any error which is not due to authentication bac
    if (err.response) {
        if (err.response.status === 403) {
            if (isLoading) {
                config.headers['Authorization'] = deCryptToken();
                return new Promise((resolve, reject) => {
                    axios.request(config)
                        .then(res => resolve(res))
                        .catch(e => reject(e))
                })
            }
            else {
                localStorage.removeItem(REACT_APP_JWT);
                localStorage.removeItem(REACT_APP_JWT_REFESH);
                localStorage.clear();
                currentStore.dispatch(actions.refreshtoken_stop())
                window.location = '/';

                //currentStore.dispatch(actions.fnLogout())
            }
        }
        // console.log(store.getState().auth.token)
        if (err.response.status === 401) {
            //  console.log(currentStore.getState().statusExpToken.isLoading)
            //   console.log(401)
            return getNewToken()
                .then((res) => {
                    let config = err.config;
                    const { accesstoken, refreshtoken } = res;
                    //  console.log(accesstoken)
                    config.headers['Authorization'] = accesstoken;
                    currentStore.dispatch(actions.refreshtoken_stop())
                    //  store.dispatch(auth_reload_token({ accesstoken }))
                    //   localStorage.setItem(config.REACT_APP_JWT, crypto.enCryptDataClient(data.accesstoken));
                    //   localStorage.setItem(config.REACT_APP_JWT_REFESH, crypto.enCryptDataClient(data.accesstoken));
                    localStorage.setItem(REACT_APP_JWT, enCryptDataClient(accesstoken));
                    localStorage.setItem(REACT_APP_JWT_REFESH, enCryptDataClient(refreshtoken));
                    return new Promise((resolve, reject) => {
                        axios.request(config)
                            .then(res => resolve(res))
                            .catch(e => reject(e))
                    })
                })
        }
    }
    return Promise.reject(err)
})

const getNewToken = () => {
    let token = deCryptRefreshToken();
    let req = {
        url: api.REFRESHTOKEN,
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        data: JSON.stringify({
            refreshToken: token
        })
    };
    return new Promise((resolve, reject) => {
        axios(req)
            .then((res) => {
                return resolve(res.data)
            })
            .catch(e => {
                return reject(e)
            })
    })
}

function checkExpToken(token) {

    var decoded = jwt.decode(token, { complete: true });
    if (decoded !== undefined && decoded !== null && decoded.payload !== undefined) {
        let now = new Date();
        let expired = new Date(decoded.payload.exp * 1000);
        if (now > expired) {
            currentStore.dispatch(actions.refreshtoken_run())
        }
    }
}

export async function axiosWithHeaderProps(method, url, data) {

    let token = deCryptToken();
    checkExpToken(token)
    let request = {
        url: url,
        method: method,
        headers: {
            "Content-Type": "application/json",
            'Authorization': token
        },
        data: JSON.stringify(data)
    };

    // let responseData = null;

    return await new Promise((resolve, reject) => {
        axios(request)
            .then((response) => {
                resolve(response);
            }).catch(err => {
                reject(err.toString());
            });
    });
};


export async function axiosWithHeaders(verb, url, data) {

    let token = deCryptToken();
    checkExpToken(token)
    let request = {
        url: url,
        method: verb,
        headers: {
            "Content-Type": "application/json",
            'Authorization': token
        },
        data: JSON.stringify(data)
    };

    // console.log(request)

    let responseData = null;

    await axios(request)
        .then((response) => {
            // console.log(request, response)
            responseData = response;
        }).catch(err => {
            Swal.fire({
                title: 'Lỗi axios-wrapper.js',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        });
    currentStore.dispatch(actions.refreshtoken_stop())
    return responseData;
};

export async function axiosWithHeadersBlob(verb, url, data) {

    let token = deCryptToken();
    let request = {
        url: url,
        method: verb,
        headers: {
            "Content-Type": "application/json",
            'Authorization': "Bearer " + token
        },
        responseType: 'blob',
        data: JSON.stringify(data)
    };
    let responseData = null;
    await axios(request)
        .then((response) => {
            responseData = response;
        }).catch(err => {
            Swal.fire({
                title: 'Lỗi axios-wrapper.js',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        });
    return responseData;
};

export async function axiosWithHeadersBlobFromUrl(verb, url, data) {
    let token = deCryptToken();
    checkExpToken(token)
    let request = {
        url: url,
        method: verb,
        headers: {
            "Content-Type": "application/json",
            'Authorization': token
        },
        responseType: 'blob',
        data: JSON.stringify(data)
    };

    let responseData = null;

    await axios(request)
        .then((response) => {
            responseData = response;
        }).catch(err => {
            Swal.fire({
                title: 'Lỗi axios-wrapper.js',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        });
    currentStore.dispatch(actions.refreshtoken_stop())
    return responseData;
};

export async function axiosWithHeadersNoToken(verb, url, data) {
    let request = {
        url: url,
        method: verb,
        headers: {
            "Content-Type": "application/json"
        },
        data: JSON.stringify(data)
    };
    let responseData = null;
    await axios(request)
        .then((response) => {
            responseData = response;
        }).catch(err => {
            // Swal.fire({
            //     title: 'Lỗi axios-wrapper.js',
            //     text: err.toString(),
            //     imageUrl: '/img/octopus/error.svg',
            //     imageWidth: 109,
            //     imageHeight: 98,
            //     imageAlt: 'Error',
            //     confirmButtonColor: '#76cc68',
            //     showCloseButton: true
            // })
            console.log(`Lỗi axios-wrapper.js 279: ${err.toString()}`)
        });
    return responseData;
};

export async function axiosWithHeadersNoTokenFormData(verb, url, data) {
    let request = {
        url: url,
        method: verb,
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data: data
    };
    let responseData = null;
    await axios(request)
        .then((response) => {
            responseData = response;
        }).catch(err => {
            Swal.fire({
                title: 'Lỗi axios-wrapper.js',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        });
    return responseData;
};

export async function axiosWithHeadersFormData(verb, url, data) {

    let token = deCryptToken();
    checkExpToken(token)
    let request = {
        url: url,
        method: verb,
        headers: {
            "Content-Type": "multipart/form-data",
            'Authorization': token
        },
        data: data
    };
    let responseData = null;
    await axios(request)
        .then((response) => {
            responseData = response;
        }).catch(err => {
            Swal.fire({
                title: 'Lỗi axios-wrapper.js',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        });
    currentStore.dispatch(actions.refreshtoken_stop())
    return responseData;
};