import * as api from '@/common/api';
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as enums from '@/common/enum';
import Swal from 'sweetalert2';

const moduleReportByProject = (projectId) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.listModuleReportProject}`, {projectId})
}

const getListColumnByTable = (objPost) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.listColumnReport}`, objPost)
}

const onSyncOptionReport = (objPost) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.syncOptionsReport}`, objPost)
}

const addModuleReport = (objAdd, updateEnd) =>{
    axiosWrapper.axiosWithHeaders("post", api.addModuleReport, objAdd)
        .then(response => {
            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === enums.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Thêm mới',
                        text: 'Thêm báo cáo thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Thêm mới',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } 
        })
        .catch((err) => {
            Swal.fire({
                title: 'Thêm mới',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
}

const updateModuleReport = (objUpdate, updateEnd) =>{
    axiosWrapper.axiosWithHeaders("post", api.updateModuleReport, objUpdate)
        .then(response => {
            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === enums.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Cập nhật',
                        text: 'Cập nhật báo cáo thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Cập nhật',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } 
        })
        .catch((err) => {
            Swal.fire({
                title: 'Cập nhật',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
}

const delModuleReport = (objDel, updateEnd) =>{
    axiosWrapper.axiosWithHeaders("post", api.delModuleReport, objDel)
        .then(response => {
            if (response.status === enums.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === enums.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Xóa',
                        text: 'Xóa báo cáo thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Xóa',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } 
        })
        .catch((err) => {
            Swal.fire({
                title: 'Xóa',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
}

const detailModuleReport = (objDetail) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.detailModuleReport}`, objDetail)
}

export{
    moduleReportByProject,
    getListColumnByTable,
    onSyncOptionReport,
    addModuleReport,
    updateModuleReport,
    detailModuleReport,
    delModuleReport
}