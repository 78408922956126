import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';

const detailFileAIDetect = (fileId) =>{
    return axiosWrapper.axiosWithHeaders("post", api.detectFileAI, { fileId });
}


const callTraining = (objTraining) => {
    return axiosWrapper.axiosWithHeaders("post", api.callTrainingAI, objTraining);
}

export{
    detailFileAIDetect,
    callTraining
}