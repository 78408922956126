import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';

const assignAll = (objAssign) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.assignAutoAll}`, objAssign)
}

const assignStoreAndUser = (objAssign) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.assignAutoStoreAndUser}`, objAssign)
}

const getExistUserStore = (objRequest) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.listExistUserStore}`, objRequest)
}

const assignStoreUserMultiple = (objAssign) => {
    // console.log(objAssign, api.assignStoreUserMultiple)
    return axiosWrapper.axiosWithHeaders("post", `${api.assignStoreUserMultiple}`, objAssign)
}

export {
    assignAll,
    assignStoreAndUser,
    getExistUserStore,
    assignStoreUserMultiple
}