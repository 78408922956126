import * as types from '../contains/types';

export const windowSize_ini = () => {
    return {
        type: types.WINDOWSIZE_INIT
    };
};

export const windowSizeChange = (windowSize) => {
    return {
        type: types.WINDOWSIZE_CHANGE,
        windowSize
    }
}

