import * as api from '@/common/api';
import axios from 'axios';
const cancelTokenSource = axios.CancelToken.source();

const uploadImageAndTime = (formData, fncCancel) => {
    fncCancel = axios.CancelToken.source();
    return axios.post(`${api.uploadMultipleTime}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        cancelToken: cancelTokenSource.token
    })
}

export {
    uploadImageAndTime
}