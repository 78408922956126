import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import { encryptApi } from '@/common/api';
import axios from 'axios';
import * as api from '@/common/api';
import * as config from '@/common/config';
import * as crypto from '@/common/Crypto';
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import Swal from 'sweetalert2';

export const auth_success = (token, profile, refresh) => {
    return {
        type: types.AUTH_SUCCESS,
        token,
        profile,
        refresh
    }
}


const auth_loading = (loading) => {
    return {
        type: types.AUTH_LOADING,
        loading
    }
}

const encryptLogin = async (objLogin) => {
    let crypt = "";
    await axios.post(encryptApi, objLogin)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                crypt = data.cry;
            } else {
                crypt = "";
            }
        })
        .catch((err) => {
            return;
        });
    return crypt;
}

const sendOtp = (username) => {
    return axios.post(api.sendOtp, { username });
}

const registerUserOTP = (username) => {
    return axios.post(api.registerApiOtp, { username });
}

const checkUserExists = (username) => {
    return axios.post(api.checkUserExists, { username });
}


const updateVerified = (obj) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.updateVerified}`, obj)
}

const detailVerified = (userId) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.detailVerified}`, { userId })
}

const updateProfile = (obj) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.updateProfile}`, obj)
}

const verifiedAccount = (objUpdate, updateEnd) => (dispatch) => {
    dispatch(auth_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.verifiedAccount, objUpdate)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Xác thực',
                        text: objUpdate.statusAuth === 1 ? 'Xác thực nhân viên thành công !': 'Hủy xác thực nhân viên thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Xác thực',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Xác thực',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Xác thực',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
            })
        })
        .finally(() => {
            dispatch(auth_loading(false));
        })
}

const fnLogin = ({ username, password }, router) => (dispatch) => {
    dispatch(auth_loading(true));
    encryptLogin({ username, password }).then(crypt => {
        // localStorage.setItem('aaa', crypt)
        if (crypt) {
            axios.post(api.loginApi, {
                "data": crypt
            })
                .then(response => {
                    if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                        const {
                            data
                        } = response;
                        if (data.status === status.STATUS_RESPONSE_OK) {
                            localStorage.setItem(config.REACT_APP_JWT, crypto.enCryptDataClient(data.accesstoken));
                            localStorage.setItem(config.REACT_APP_JWT_REFESH, crypto.enCryptDataClient(data.refreshtoken));
                            console.log(data.accesstoken);
                            /*Admin*/
                            router.push('/company')
                            dispatch(auth_loading(false));
                        } else {
                            Swal.fire({
                                title: 'Lỗi',
                                text: data.message,
                                imageUrl: '/img/octopus/error.svg',
                                imageWidth: 109,
                                imageHeight: 98,
                                imageAlt: 'Error',
                                confirmButtonColor: '#76cc68',
                                showCloseButton: true
                            })
                            dispatch(auth_loading(false));
                        }
                    } else {
                        Swal.fire({
                            title: 'Lỗi',
                            text: response.statusText,
                            imageUrl: '/img/octopus/error.svg',
                            imageWidth: 109,
                            imageHeight: 98,
                            imageAlt: 'Error',
                            confirmButtonColor: '#76cc68',
                            showCloseButton: true
                        })
                        dispatch(auth_loading(false));
                    }
                }).catch((err) => {
                    Swal.fire({
                        title: 'Lỗi',
                        text: err.toString(),
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                    dispatch(auth_loading(false));
                })
        } else {
            Swal.fire({
                title: 'Lỗi',
                text: "Không thể decode username và password",
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
            dispatch(auth_loading(false));
        }
    }).finally(() => {
        document.querySelector("#__next").classList.remove("dialog-open");
    })
}

const fnLoginOtp = ({ username, otp }, updateEnd) => (dispatch) => {
    dispatch(auth_loading(true));

    axios.post(api.loginApiOtp, { username, otp })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    localStorage.setItem(config.REACT_APP_JWT, crypto.enCryptDataClient(data.accesstoken));
                    localStorage.setItem(config.REACT_APP_JWT_REFESH, crypto.enCryptDataClient(data.refreshtoken));

                    dispatch(auth_success(data.accesstoken, crypto.GetProfileString(data.accesstoken), data.refreshtoken));

                    updateEnd();

                    dispatch(auth_loading(false));
                } else {
                    Swal.fire({
                        title: 'Lỗi Đăng Nhập',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                    dispatch(auth_loading(false));
                }
            } else {
                Swal.fire({
                    title: 'Lỗi Đăng Nhập',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
                dispatch(auth_loading(false));
            }
        }).catch((err) => {
            console.log(err)
            Swal.fire({
                title: 'Lỗi Đăng Nhập',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
            dispatch(auth_loading(false));
        }).finally(() => {
            document.querySelector("#__next").classList.remove("dialog-open");
        })
}

const fnLoginSocial = ({ type, socialId }, updateEnd) => (dispatch) => {
    dispatch(auth_loading(true));

    axios.post(api.login_social, { type, socialId })
        .then(response => {
            if (response && response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    localStorage.setItem(config.REACT_APP_JWT, crypto.enCryptDataClient(data.accesstoken));
                    localStorage.setItem(config.REACT_APP_JWT_REFESH, crypto.enCryptDataClient(data.refreshtoken));
                    dispatch(auth_success(data.accesstoken, crypto.GetProfileString(data.accesstoken), data.refreshtoken)); 
                } 

                // console.log(data);
                // else {
                //     Swal.fire({
                //         title: 'Lỗi',
                //         text: data.message,
                //         imageUrl: '/img/octopus/error.svg',
                //         imageWidth: 109,
                //         imageHeight: 98,
                //         imageAlt: 'Error',
                //         confirmButtonColor: '#76cc68',
                //         showCloseButton: true
                //     })
                //     dispatch(auth_loading(false));
                // }
                updateEnd(type, data);
                dispatch(auth_loading(false));
            }
        }).catch((err) => {
            Swal.fire({
                title: 'Lỗi',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
            dispatch(auth_loading(false));
        }).finally(() => {
            document.querySelector("#__next").classList.remove("dialog-open");
        })
}

const fnLogout = (router) => {
    localStorage.removeItem(config.REACT_APP_JWT);
    localStorage.removeItem(config.REACT_APP_JWT_REFESH);
    localStorage.clear();
    router.push(`/`);
}

const registerSocial = (objSocial) => {
    return axios.post(api.register_social, objSocial);
}

export {
    encryptLogin,
    fnLogin,
    fnLogout,
    fnLoginOtp,
    sendOtp,
    registerUserOTP,
    updateProfile,
    checkUserExists,
    updateVerified,
    detailVerified,
    verifiedAccount,
    fnLoginSocial,
    registerSocial
}