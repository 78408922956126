import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2';

const ai_input_ini = () => {
    return {
        type: types.AI_INPUT_INI
    };
};

const ai_input_success = (options) => {
    return {
        type: types.AI_INPUT_SUCCESS,
        options
    }
}

const ai_input_loading = (loading) => {
    return {
        type: types.AI_INPUT_LOADING,
        loading
    }
}

const getListAIInput = (surveyId) => (dispatch) => {
    dispatch(ai_input_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.listAIInputDropdown, { surveyId })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                     console.log(data);
                    dispatch(ai_input_success(data.result));
                } else {
                    Swal.fire({
                        title: 'Loại dữ liệu A.I',
                        text: data.message,
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Loại dữ liệu A.I',
                    text: response.statusText,
                    imageUrl: '/img/octopus/success.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Success',
                    showConfirmButton: false,
                    timer: 3000,
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Loại dữ liệu A.I',
                text: err.toString(),
                imageUrl: '/img/octopus/success.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Success',
                showConfirmButton: false,
                timer: 3000,
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(ai_input_loading(false));
        })
}

const getListAIInputProject = (projectId, companyId) => (dispatch) => {
    dispatch(ai_input_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.listAIInputProjectDropdown, { projectId, companyId })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    dispatch(ai_input_success(data.result));
                } else {
                    Swal.fire({
                        title: 'Loại dữ liệu A.I',
                        text: data.message,
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Loại dữ liệu A.I',
                    text: response.statusText,
                    imageUrl: '/img/octopus/success.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Success',
                    showConfirmButton: false,
                    timer: 3000,
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Loại dữ liệu A.I',
                text: err.toString(),
                imageUrl: '/img/octopus/success.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Success',
                showConfirmButton: false,
                timer: 3000,
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(ai_input_loading(false));
        })
}


export {
    getListAIInput,
    getListAIInputProject
}
