import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import * as crypt from '@/common/Crypto';
import Swal from 'sweetalert2'

const user_company_ini = () => {
    return {
        type: types.USER_COMPANY_DETAIL_INI
    };
};

const user_company_success = (detail, companys) => {
    return {
        type: types.USER_COMPANY_DETAIL_SUCCESS,
        detail,
        companys
    }
}

const user_company_project = (totalproject) => {
    return {
        type: types.USER_COMPANY_PROJECT_SUCCESS,
        totalproject
    }
}

const user_company_loading = (loading) => {
    return {
        type: types.USER_COMPANY_DETAIL_LOADING,
        loading
    }
}

const addUserCompany = (objAdd, updateEnd) => (dispatch) => {
    // dispatch(role_company_loading(true));
    const data = crypt.enCryptData(objAdd);
    axiosWrapper.axiosWithHeaders("post", api.createUserCompany, { data })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Thêm mới',
                        text: 'Tạo nhân viên công ty thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Thêm mới',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Thêm mới',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Thêm mới',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(user_company_loading(false));
        })
}

const detailUserCompany = (objPost) => (dispatch) => {
    dispatch(user_company_loading(true));
    const data = crypt.enCryptData(objPost);
    axiosWrapper.axiosWithHeaders("post", api.detailUserCompany, { data })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    const { result } = data;
                    dispatch(user_company_success(result.detail, result.companys))
                } else {
                    Swal.fire({
                        title: 'Chi tiết nhân viên',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Chi tiết nhân viên',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Chi tiết nhân viên',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(user_company_loading(false));
        })
}

const detailCompanyProject = (objPost) => (dispatch) => {
    dispatch(user_company_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.detailCompanyProject, objPost)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    const { result } = data;
                    dispatch(user_company_project(result))
                } else {
                    Swal.fire({
                        title: 'Chi tiết nhân viên (dự án)',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Chi tiết nhân viên (dự án)',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Chi tiết nhân viên (dự án)',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(user_company_loading(false));
        })
}

const updateUserCompany = (objUpdate, updateEnd) => (dispatch) => {
    dispatch(user_company_loading(true));
    const data = crypt.enCryptData(objUpdate);
    axiosWrapper.axiosWithHeaders("post", api.updateUserCompany, { data })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Cập nhật',
                        text: 'Cập nhật nhân viên công ty thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Chi tiết nhân viên',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Chi tiết nhân viên',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Chi tiết nhân viên',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(user_company_loading(false));
        })
}

const deleteUserCompany = (objDelete, updateEnd) => (dispatch) => {
    // dispatch(role_company_loading(true));
    const data = crypt.enCryptData(objDelete);

    axiosWrapper.axiosWithHeaders("post", api.deleteUserCompany, { data })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Xóa',
                        text: 'Xóa nhân viên công ty thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();                    
                } else {
                    Swal.fire({
                        title: 'Xóa',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Xóa',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Xóa',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(user_company_loading(false));
        })
}


const checkPhoneExist = (username) => {
    // dispatch(store_detail_loading(true));
    return axiosWrapper.axiosWithHeaders("post", api.checkPhoneExist, { username })
        // .then(response => {
        //     if (response.status === status.STATUS_RESPONSE_OK && response.data) {
        //         const {
        //             data
        //         } = response;
        //         if (data.status === status.STATUS_RESPONSE_OK) {
        //             dispatch(store_extracolumns_success(data.result))
        //         } else {
        //             Swal.fire({
        //                 title: 'Thông tin địa điểm',
        //                 text: data.message,
        //                 imageUrl: '/img/octopus/error.svg',
        //                 imageWidth: 109,
        //                 imageHeight: 98,
        //                 imageAlt: 'Error',
        //                 confirmButtonColor: '#76cc68',
        //                 showCloseButton: true
        //             })
        //         }
        //     } else {
        //         Swal.fire({
        //             title: 'Thông tin địa điểm',
        //             text: response.statusText,
        //             imageUrl: '/img/octopus/error.svg',
        //             imageWidth: 109,
        //             imageHeight: 98,
        //             imageAlt: 'Error',
        //             confirmButtonColor: '#76cc68',
        //             showCloseButton: true
        //         })
        //     }
        // })
        // .catch((err) => {
        //     Swal.fire({
        //         title: 'Thông tin địa điểm',
        //         text: err.toString(),
        //         imageUrl: '/img/octopus/error.svg',
        //         imageWidth: 109,
        //         imageHeight: 98,
        //         imageAlt: 'Error',
        //         confirmButtonColor: '#76cc68',
        //         showCloseButton: true
        //     })
        // })
        // .finally(() => {
        //     dispatch(store_detail_loading(false));
        // })
}


export {
    addUserCompany,
    updateUserCompany,
    detailUserCompany,
    detailCompanyProject,
    deleteUserCompany,
    checkPhoneExist
}