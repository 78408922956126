import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2';

const user_project_ini = () => {
    return {
        type: types.USER_PROJECT_INI
    };
};

const user_project_success = (options) => {
    return {
        type: types.USER_PROJECT_SUCCESS,
        options
    }
}

const user_project_loading = (loading) => {
    return {
        type: types.USER_PROJECT_LOADING,
        loading
    }
}

const getListUserProject = (companyId, projectId) => (dispatch) => {
    dispatch(user_project_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.listUserProjectDropdown, { companyId, projectId })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    dispatch(user_project_success(data.result));
                } else {
                    Swal.fire({
                        title: 'Nhân viên (dự án)',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Nhân viên (dự án)',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Nhân viên (dự án)',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(user_project_loading(false));
        })
}

const GetListUserProject2 = (companyId, projectId) => {
    return axiosWrapper.axiosWithHeaders("post", api.listUserProjectDropdown, { companyId, projectId })
}

export {
    getListUserProject,
    GetListUserProject2,
}
