export const STATUS_RESPONSE_OK = 200;
export const STATUS_RESPONSE_NON_AUTHORITATIVE_INFORMATION = 203;
export const STATUS_RESPONSE_BAD_REQUEST = 400;
export const STATUS_RESPONSE_UNAUTHORIZED = 401;
export const STATUS_RESPONSE_FORBIDDEN = 403;
export const STATUS_RESPONSE_NOT_FOUND = 404;
export const STATUS_RESPONSE_METHOD_NOT_ALLOWED = 405;
export const STATUS_RESPONSE_REQUEST_TIMEOUT = 408;
export const STATUS_RESPONSE_UNSUPPORTED_MEDIA_TYPE = 415;
export const STATUS_RESPONSE_INTERNAL_SERVER_ERROR = 500;
export const STATUS_RESPONSE_BAD_GATEWAY = 502;
export const STATUS_RESPONSE_SERVICE_UNAVAILABLE = 503;
export const STATUS_RESPONSE_GATEWAY_TIMEOUT = 504;
export const STATUS_RESPONSE_TOKEN_INVALID = 600;
export const STATUS_RESPONSE_USER_NOT_FOUND = 601;
export const STATUS_RESPONSE_USER_SUSPEND = 602;
export const STATUS_RESPONSE_FEATURE_SUSPEND = 603;
export const STATUS_RESPONSE_PERMISSION_DENIED = 604;
export const STATUS_RESPONSE_NO_DATA_FOUND = 605;
export const STATUS_RESPONSE_UPDATE_FAILED = 606;
export const STATUS_RESPONSE_EXCEPTION = 999;



export const tableCompany = {
  PROJECT: "PROJECT",
  USERCOMPANY: 'USERCOMPANY',
  ROLECOMPANY: 'ROLECOMPANY',
  PRODUCT: 'PRODUCT',
  AI: 'AI',
  NEWS: 'NEWS',
  NOTIFICATION: 'NOTIFICATION',
  PRODUCTGROUP: 'PRODUCTGROUP',
  OVERVIEW: 'OVERVIEW'
}


export const tableProject = {
  STORE: "STORE",
  USERPROJECT: 'USERPROJECT',
  ROLEPROJECT: 'ROLEPROJECT',
  SURVEY: 'SURVEY',
  NOTIFICATION: 'NOTIFICATION',
  DATASURVEY: 'DATASURVEY',
  HISTORY: 'HISTORY',
  DASHBOARD: 'DASHBOARD',
  REPORT: 'REPORT',
  AI: 'AI',
  PRODUCT: 'PRODUCT',
  CONFIGAI: 'CONFIGAI',
  STOREUSER: 'STOREUSER',
  OVERVIEW: 'OVERVIEW',
  SCREEN: 'SCREEN',
  SURVEYGROUP: 'SURVEYGROUP',
  PREVIEWCONFIG: 'PREVIEWCONFIG',
  CONFIGREPORT: 'CONFIGREPORT',
  CONFIGAIINSURVEY: 'CONFIGAIINSURVEY',
  CONFIGSTORE: 'CONFIGSTORE',
  CONFIGFILTER: "CONFIGFILTER"
}