import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2'


const ai_config_survey_success = (detail) => {
    return {
        type: types.AI_SURVEY_CONFIG_SUCCESS,
        detail
    }
}

const ai_config_survey_loading = (loading) => {
    return {
        type: types.AI_SURVEY_CONFIG_LOADING,
        loading
    }
}

const addSurveyConfigAI = (objAdd, updateEnd) => (dispatch) => {
    dispatch(ai_config_survey_loading(true))
    axiosWrapper.axiosWithHeaders("post", api.createSurveyConfigAI, objAdd)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Thêm mới',
                        text: 'Tạo cấu hình A.I thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {                   
                    Swal.fire({
                        title: 'Thêm mới',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Thêm mới',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Thêm mới',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(ai_config_survey_loading(false))
        })
}

const updateSurveyConfigAI = (objUpdate, updateEnd) => (dispatch) => {
    dispatch(ai_config_survey_loading(true))
    axiosWrapper.axiosWithHeaders("post", api.updateSurveyConfigAI, objUpdate)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Cập nhật',
                        text: 'Cập nhật cấu hình A.I thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Cập nhật',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Cập nhật',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Cập nhật',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(ai_config_survey_loading(false))
        })
}

const deleteSurveyConfigAI = (id, updateEnd) => (dispatch) => {
    dispatch(ai_config_survey_loading(true))
    axiosWrapper.axiosWithHeaders("post", api.deleteSurveyConfigAI, { id })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Xóa',
                        text: 'Xóa cấu hình A.I thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Xóa',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Xóa',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Xóa',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(ai_config_survey_loading(false))
        })
}

const detailSurveyConfigAI = (id) => {
    // dispatch(ai_config_survey_loading(true));
    return axiosWrapper.axiosWithHeaders("get", `${api.detailSurveyConfigAI}?id=${id}`, null)
        // .then(response => {
        //     if (response.status === status.STATUS_RESPONSE_OK && response.data) {
        //         const {
        //             data
        //         } = response;
        //         if (data.status === status.STATUS_RESPONSE_OK) {
        //             const { result } = data;
        //             dispatch(ai_config_survey_success(result))
        //         } else {
        //             Swal.fire({
        //                 title: 'Chi tiết cấu hình A.I',
        //                 text: data.message,
        //                 imageUrl: '/img/octopus/error.svg',
        //                 imageWidth: 109,
        //                 imageHeight: 98,
        //                 imageAlt: 'Error',
        //                 confirmButtonColor: '#76cc68',
        //                 showCloseButton: true
        //             })
        //         }
        //     } else {
        //         Swal.fire({
        //             title: 'Chi tiết cấu hình A.I',
        //             text: response.statusText,
        //             imageUrl: '/img/octopus/error.svg',
        //             imageWidth: 109,
        //             imageHeight: 98,
        //             imageAlt: 'Error',
        //             confirmButtonColor: '#76cc68',
        //             showCloseButton: true
        //         })
        //     }
        // })
        // .catch((err) => {
        //     Swal.fire({
        //         title: 'Chi tiết cấu hình A.I',
        //         text: err.toString(),
        //         imageUrl: '/img/octopus/error.svg',
        //         imageWidth: 109,
        //         imageHeight: 98,
        //         imageAlt: 'Error',
        //         confirmButtonColor: '#76cc68',
        //         showCloseButton: true
        //     })
        // })
        // .finally(() => {
        //     dispatch(ai_config_survey_loading(false));
        // })
}


export {
    addSurveyConfigAI,
    updateSurveyConfigAI,
    detailSurveyConfigAI,
    deleteSurveyConfigAI
}