import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2'

const dashboardStoreAndSurvey = (objFilter) => {
    return axiosWrapper.axiosWithHeaders("post", api.dashboard_store_and_survey, objFilter);
}

export {
    dashboardStoreAndSurvey
}