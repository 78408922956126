import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2'

const config_column_ini = () => {
    return {
        type: types.CONFIG_COLUMN_INI
    };
};

const config_column_success = (detail) => {
    return {
        type: types.CONFIG_COLUMN_SUCCESS,
        detail
    }
}

const config_column_loading = (loading) => {
    return {
        type: types.CONFIG_COLUMN_LOADING,
        loading
    }
}

const addConfigColumnStore = (objAdd, updateEnd) => (dispatch) => {
    dispatch(config_column_loading(true))
    axiosWrapper.axiosWithHeaders("post", api.createConfigColumnStore, objAdd)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Thêm mới',
                        text: 'Tạo cột dữ liệu thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Thêm mới',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Thêm mới',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Thêm mới',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(config_column_loading(false))
        })
}

const updateConfigColumnStore = (objUpdate, updateEnd) => (dispatch) => {
    dispatch(config_column_loading(true))
    axiosWrapper.axiosWithHeaders("post", api.updateConfigColumnStore, objUpdate)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Cập nhật',
                        text: 'Cập nhật cấu hình cột dữ liệu thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Cập nhật',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Cập nhật',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Cập nhật',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(config_column_loading(false))
        })
}

const deleteConfigColumnStore = (id, updateEnd) => (dispatch) => {
    dispatch(config_column_loading(true))
    axiosWrapper.axiosWithHeaders("post", api.deteteConfigColumnStore, { id })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Xóa',
                        text: 'Xóa cấu hình cột dữ liệu thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Xóa',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Xóa',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Xóa',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(config_column_loading(false))
        })
}

const detailConfigColumnStore = (id,projectId) => (dispatch) => {
    dispatch(config_column_loading(true));
    axiosWrapper.axiosWithHeaders("post", `${api.detailConfigColumnStore}`, {id,projectId})
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    const { result } = data;
                    dispatch(config_column_success(result))
                } else {
                    Swal.fire({
                        title: 'Chi tiết Cấu hình cột',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Chi tiết Cấu hình cột',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Chi tiết Cấu hình cột',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(config_column_loading(false));
        })
}


export {
    addConfigColumnStore,
    detailConfigColumnStore,
    updateConfigColumnStore,
    deleteConfigColumnStore
}