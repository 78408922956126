import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';

const linkSocial = (objSocial) => {
    return axiosWrapper.axiosWithHeaders("post", api.link_social, objSocial)
}

const unlinkSocial = (objSocial) => {
    return axiosWrapper.axiosWithHeaders("post", api.unlink_social, objSocial)
}

const checkSocialExists = (objSocial) => {
    return axiosWrapper.axiosWithHeaders("post", api.check_exist_social, objSocial)
}

export {
    linkSocial,
    unlinkSocial,
    checkSocialExists
}