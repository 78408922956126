import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2';

// const role_project_ini = () => {
//     return {
//         type: types.ROLE_PROJECT_INI
//     };
// };

const role_project_success = (list) => {
    return {
        type: types.ROLE_PROJECT_SUCCESS,
        list
    }
}

const role_project_detail = (detail) => {
    return {
        type: types.ROLE_PROJECT_DETAIL,
        detail
    }
}

// const cog_role_project_ini = () => {
//     return {
//         type: types.CONFIG_ROLE_PROJECT_INI
//     };
// };

const cog_role_project_success = (config) => {
    return {
        type: types.CONFIG_ROLE_PROJECT_SUCCESS,
        config
    }
}

const role_project_loading = (loadingList) => {
    return {
        type: types.ROLE_PROJECT_LOADING,
        loadingList
    }
}

const cog_role_project_loading = (loadingConfig) => {
    return {
        type: types.CONFIG_ROLE_PROJECT_LOADING,
        loadingConfig
    }
}

const getListRoleProject = (projectId) => {
    // dispatch(role_project_loading(true));
    return axiosWrapper.axiosWithHeaders("post", api.listRoleProjectDropdown, { projectId })
        // .then(response => {
        //     if (response.status === status.STATUS_RESPONSE_OK && response.data) {
        //         const {
        //             data
        //         } = response;
        //         if (data.status === status.STATUS_RESPONSE_OK) {
        //             dispatch(role_project_success(data.result));
        //         } else {
        //             Swal.fire({
        //                 title: 'Quyền truy cập dự án',
        //                 text: data.message,
        //                 imageUrl: '/img/octopus/error.svg',
        //                 imageWidth: 109,
        //                 imageHeight: 98,
        //                 imageAlt: 'Error',
        //                 confirmButtonColor: '#76cc68',
        //                 showCloseButton: true
        //             })
        //         }
        //     } else {
        //         Swal.fire({
        //             title: 'Quyền truy cập dự án',
        //             text: response.statusText,
        //             imageUrl: '/img/octopus/error.svg',
        //             imageWidth: 109,
        //             imageHeight: 98,
        //             imageAlt: 'Error',
        //             confirmButtonColor: '#76cc68',
        //             showCloseButton: true
        //         })
        //     }
        // })
        // .catch((err) => {
        //     Swal.fire({
        //         title: 'Quyền truy cập dự án',
        //         text: err.toString(),
        //         imageUrl: '/img/octopus/error.svg',
        //         imageWidth: 109,
        //         imageHeight: 98,
        //         imageAlt: 'Error',
        //         confirmButtonColor: '#76cc68',
        //         showCloseButton: true
        //     })
        // })
        // .finally(() => {
        //     dispatch(role_project_loading(false));
        // })
}

const getConfigRoleProject = () => (dispatch) => {
    dispatch(cog_role_project_loading(true));
    axiosWrapper.axiosWithHeaders("get", api.configRoleProject, null)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    // console.log(data.result);
                    dispatch(cog_role_project_success(data.result));
                } else {
                    Swal.fire({
                        title: 'Cấu hình quyền truy cập dự án',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Cấu hình quyền truy cập dự án',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Cấu hình quyền truy cập dự án',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(cog_role_project_loading(false));
        })
}

const getConfigRoleProject2 = () => {
    // dispatch(cog_role_project_loading(true));
    return axiosWrapper.axiosWithHeaders("get", api.configRoleProject, null)
}

const addRoleProject = (objAdd, updateEnd) => (dispatch) => {
    dispatch(role_project_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.createRoleProject, objAdd)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Tạo phân quyền',
                        text: 'Tạo quyền truy cập dự án thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Tạo phân quyền',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Tạo phân quyền',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Tạo phân quyền',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(role_project_loading(false));
        })
}

const updateRoleProject = (objUpdate, updateEnd) => (dispatch) => {
    dispatch(role_project_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.updateRoleProject, objUpdate)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Cập nhật phân quyền',
                        text: 'Cập nhật quyền truy cập dự án thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Cập nhật phân quyền',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Cập nhật phân quyền',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Cập nhật phân quyền',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(role_project_loading(false));
        })
}

const detailRoleProject = (companyId, projectId, roleProjectId) => (dispatch) => {
    dispatch(role_project_loading(true));
    axiosWrapper.axiosWithHeaders("post", `${api.detailRoleProject}`, { companyId, projectId, roleProjectId })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    console.log(data);
                    dispatch(role_project_detail(data.result))
                } else {
                    Swal.fire({
                        title: 'Chi tiết quyền truy cập dự án',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Chi tiết quyền truy cập dự án',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Chi tiết quyền truy cập dự án',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(role_project_loading(false));
        })
}

const detailRoleProject2 = (companyId, projectId, roleProjectId) => {
    return axiosWrapper.axiosWithHeaders("post", `${api.detailRoleProject}`, { companyId, projectId, roleProjectId })
}

const deleteRoleProject = (roleProjectId, updateEnd) => (dispatch) => {
    dispatch(role_project_loading(true));
    axiosWrapper.axiosWithHeaders("post", api.deleteRoleProject, { roleProjectId })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Quyền truy cập dự án',
                        text: 'Xóa quyền truy cập dự án thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd()
                } else {
                    Swal.fire({
                        title: 'Quyền truy cập dự án',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Quyền truy cập dự án',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Quyền truy cập dự án',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(role_project_loading(false));
        })
}

export {
    getListRoleProject,
    getConfigRoleProject,
    getConfigRoleProject2,
    addRoleProject,
    updateRoleProject,
    detailRoleProject,
    detailRoleProject2,
    deleteRoleProject
}
