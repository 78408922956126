import * as types from '@/redux/contains/types';
import * as status from '@/common/enum'
import * as axiosWrapper from '@/redux/contains/axios-wrapper';
import * as api from '@/common/api';
import Swal from 'sweetalert2'

const survey_detail_ini = () => {
    return {
        type: types.SURVEY_DETAIL_INI
    };
};

const survey_detail_success = (detail) => {
    return {
        type: types.SURVEY_DETAIL_SUCCESS,
        detail
    }
}

const survey_detail_loading = (loading) => {
    return {
        type: types.SURVEY_DETAIL_LOADING,
        loading
    }
}

const addSurvey = (objAdd, updateEnd) => (dispatch) => {
    dispatch(survey_detail_loading(true))

    // updateEnd(1);
    axiosWrapper.axiosWithHeaders("post", api.createSurvey, objAdd)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Thêm mới',
                        text: 'Thêm mới khảo sát thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    const {
                        surveyId
                    } = data.result;
                    updateEnd(surveyId);
                } else {
                    Swal.fire({
                        title: 'Thêm mới',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Thêm mới',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Thêm mới khảo sát',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(survey_detail_loading(false));
        })
}

const detailSurvey = (surveyId, projectId) => {
    // dispatch(survey_detail_loading(true));
    return axiosWrapper.axiosWithHeaders("post", `${api.detailSurvey}`, { surveyId, projectId })
        // .then(response => {
        //     if (response.status === status.STATUS_RESPONSE_OK && response.data) {
        //         const {
        //             data
        //         } = response;
        //         if (data.status === status.STATUS_RESPONSE_OK) {
        //             const { result } = data;
        //             dispatch(survey_detail_success(result))
        //         } else {
        //             Swal.fire({
        //                 title: 'Chi tiết khảo sát',
        //                 text: data.message,
        //                 imageUrl: '/img/octopus/error.svg',
        //                 imageWidth: 109,
        //                 imageHeight: 98,
        //                 imageAlt: 'Error',
        //                 confirmButtonColor: '#76cc68',
        //                 showCloseButton: true
        //             })
        //         }
        //     } else {
        //         Swal.fire({
        //             title: 'Chi tiết khảo sát',
        //             text: response.statusText,
        //             imageUrl: '/img/octopus/error.svg',
        //             imageWidth: 109,
        //             imageHeight: 98,
        //             imageAlt: 'Error',
        //             confirmButtonColor: '#76cc68',
        //             showCloseButton: true
        //         })
        //     }
        // })
        // .catch((err) => {
        //     Swal.fire({
        //         title: 'Chi tiết khảo sát',
        //         text: err.toString(),
        //         imageUrl: '/img/octopus/error.svg',
        //         imageWidth: 109,
        //         imageHeight: 98,
        //         imageAlt: 'Error',
        //         confirmButtonColor: '#76cc68',
        //         showCloseButton: true
        //     })
        // })
        // .finally(() => {
        //     dispatch(survey_detail_loading(false));
        // })
}

const transferSurveyDataGroup = (objUpdate) => {
    // dispatch(survey_detail_loading(true));
    return axiosWrapper.axiosWithHeaders("post", `${api.moveDataGroup}`, objUpdate)
}

const getListSurveyByProjectId = (companyId, projectId) => {
    // console.log(companyId, projectId );
    return axiosWrapper.axiosWithHeaders("post", `${api.listSurveyByProject}`, { companyId, projectId })
        // .then(response => {
        //     if (response.status === status.STATUS_RESPONSE_OK && response.data) {
        //         const {
        //             data
        //         } = response;
        //         if (data.status === status.STATUS_RESPONSE_OK) {
        //             const { result } = data;
        //             dispatch(survey_detail_success(result))
        //         } else {
        //             Swal.fire({
        //                 title: 'Chi tiết khảo sát',
        //                 text: data.message,
        //                 imageUrl: '/img/octopus/error.svg',
        //                 imageWidth: 109,
        //                 imageHeight: 98,
        //                 imageAlt: 'Error',
        //                 confirmButtonColor: '#76cc68',
        //                 showCloseButton: true
        //             })
        //         }
        //     } else {
        //         Swal.fire({
        //             title: 'Chi tiết khảo sát',
        //             text: response.statusText,
        //             imageUrl: '/img/octopus/error.svg',
        //             imageWidth: 109,
        //             imageHeight: 98,
        //             imageAlt: 'Error',
        //             confirmButtonColor: '#76cc68',
        //             showCloseButton: true
        //         })
        //     }
        // })
        // .catch((err) => {
        //     Swal.fire({
        //         title: 'Chi tiết khảo sát',
        //         text: err.toString(),
        //         imageUrl: '/img/octopus/error.svg',
        //         imageWidth: 109,
        //         imageHeight: 98,
        //         imageAlt: 'Error',
        //         confirmButtonColor: '#76cc68',
        //         showCloseButton: true
        //     })
        // })
        // .finally(() => {
        //     dispatch(survey_detail_loading(false));
        // })
}

const updateSurvey = (objUpdate, updateEnd) => (dispatch) => {
    dispatch(survey_detail_loading(true))
    axiosWrapper.axiosWithHeaders("post", api.updateSurvey, objUpdate)
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Cập nhật',
                        text: 'Cập nhật khảo sát thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Cập nhật khảo sát',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Cập nhật khảo sát',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Cập nhật khảo sát',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
        .finally(() => {
            dispatch(survey_detail_loading(false));
        })
}

const deleteSurvey = (surveyId, projectId,companyId, updateEnd) => (dispatch) => {
    axiosWrapper.axiosWithHeaders("post", api.deleteSurvey, { surveyId, projectId,companyId })
        .then(response => {
            if (response.status === status.STATUS_RESPONSE_OK && response.data) {
                const {
                    data
                } = response;
                if (data.status === status.STATUS_RESPONSE_OK) {
                    Swal.fire({
                        title: 'Xóa',
                        text: 'Xóa khảo sát thành công !',
                        imageUrl: '/img/octopus/success.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Success',
                        showConfirmButton: false,
                        timer: 3000,
                        showCloseButton: true
                    })
                    updateEnd();
                } else {
                    Swal.fire({
                        title: 'Xóa',
                        text: data.message,
                        imageUrl: '/img/octopus/error.svg',
                        imageWidth: 109,
                        imageHeight: 98,
                        imageAlt: 'Error',
                        confirmButtonColor: '#76cc68',
                        showCloseButton: true
                    })
                }
            } else {
                Swal.fire({
                    title: 'Xóa',
                    text: response.statusText,
                    imageUrl: '/img/octopus/error.svg',
                    imageWidth: 109,
                    imageHeight: 98,
                    imageAlt: 'Error',
                    confirmButtonColor: '#76cc68',
                    showCloseButton: true
                })
            }
        })
        .catch((err) => {
            Swal.fire({
                title: 'Xóa',
                text: err.toString(),
                imageUrl: '/img/octopus/error.svg',
                imageWidth: 109,
                imageHeight: 98,
                imageAlt: 'Error',
                confirmButtonColor: '#76cc68',
                showCloseButton: true
            })
        })
}

export {
    addSurvey,
    detailSurvey,
    updateSurvey,
    deleteSurvey,
    getListSurveyByProjectId,
    transferSurveyDataGroup
}